//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function Filtertemplate({
  responseData,
  filterData,
  chklname,
  docNumber,
  depname,
  malur,
  medopharm,
  tableHeaders,
}) {
  const [resData, setResData] = useState([]);

  // header details
  const data1 = {
    data: depname,
    colSpan: "5",
  };
  const data2 = {
    data: `${docNumber}`,
    colSpan: "1",
  };

  const data3 = {
    data: `${chklname}`,
    colSpan: "6",
  };

  let resDATA = [];
  let headingName2 = [];
  let headings = [];

  // Example usage:

  let obj = {};

  if (docNumber == "MAINT/003:F2-19") {
    headingName2 = [
      {
        data: `Frequency - fortnightly`,
        textAlign: "right",
        rowSpan: "1",
        colSpan: "21",
      },
    ];
  } else if (docNumber == "MAINT/003:F17-01") {
    headingName2 = [
      {
        data: `Frequency - Weekly once`,
        textAlign: "right",
        rowSpan: "1",
        colSpan: "21",
      },
    ];
  } else if (
    docNumber == "MAINT/003:F4-06" ||
    docNumber == "MAINT/003:F3-11" ||
    docNumber == "MAINT/003:F1-18" ||
    docNumber == "MAINT/003:F5-03" ||
    docNumber == "MAINT/003:F7-04"
  ) {
    headingName2 = [
      {
        data: `Frequency - weekly`,
        textAlign: "right",
        rowSpan: "1",
        colSpan: "21",
      },
    ];
  }

  if (docNumber == "MAINT/003:F7-04" || docNumber=='MAINT/003:F20-02') {
    if(docNumber == "MAINT/003:F7-04"){
      responseData?.maint_checklist?.forEach((el) => {
        (obj[`${el?.asset_name} (${el?.asset_area}) (${el?.asset_id}) `] ??=
          []).push(el);
      });
    }else if (docNumber=='MAINT/003:F20-02'){
      headingName2 = [
        {
          data: `FREQUENCY - WEEKLY ONCE`,
          textAlign: "left",
          rowSpan: "1",
          colSpan: "21",
        },
      ];
      responseData?.maint_checklist?.forEach((el) => {
        (obj[`${el?.asset_id} (${el?.asset_name})`] ??=
          []).push(el);
      });
    }
   

    const firstKey = Object.keys(obj)[0];
    const firstValue = Math.ceil(obj[firstKey]?.length / 4);

    for (let i = 0; i < firstValue; i++) {
      let start = i * 4;
      let end = (i + 1) * 4;
      let index = 1;
      let arrayresponse = [];
      let headingcount = 0;
      Object.keys(obj).forEach((key) => {
        const Value = obj[key];
        let rows = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        if (headingcount == 0) {
          const startMonth =
            obj[key][start]?.maint_sch_date !== "" &&
            obj[key][start]?.maint_sch_date !== undefined
              ? moment(obj[key][start]?.maint_sch_date)
                  .local()
                  .format("MMMM")
                  .toUpperCase()
              : "";
          const endMonth =
            obj[key][end]?.maint_sch_date !== "" &&
            obj[key][end]?.maint_sch_date !== undefined
              ? moment(obj[key][end]?.maint_sch_date)
                  .local()
                  .format("MMMM")
                  .toUpperCase()
              : "";
          headings.push({
            data: `${chklname} FOR THE MONTH OF ${
              startMonth === endMonth || endMonth == ""
                ? startMonth
                : `${startMonth} - ${endMonth}`
            }`,
            colSpan: "6",
          });
        }

        headingcount++;

        for (let j = start; j < end; j++) {
          const processCheckpoints = (dataKey1, dataKey2) => {
            const checkpoints =
              Value[j]?.maintenance_activities[0]?.checkpoints || [];
            const checkpointCount =
              Value[0]?.maintenance_activities[0]?.checkpoints.length;
            // console.log("checkpointCount",checkpointCount)

            const commonStyle = { textAlign: "center", colSpan: 2 };

            // Set the properties for rows[0]
            rows[0][dataKey1] = {
              value:
                checkpoints[0]?.cp_answer !== "" &&
                checkpoints[0]?.cp_answer !== undefined
                  ? checkpoints[0]?.cp_answer
                  : "-",
              style: commonStyle,
            };
            rows[0][dataKey2] = {
              value:
                Value[j]?.next_run !== "" && Value[j]?.next_run !== undefined
                  ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                  : "-",
              style: commonStyle,
            };
            // Set properties for additional rows
            for (let i = 1; i < 10; i++) {
              if (i < checkpointCount) {
                rows[i][dataKey1] = {
                  value:
                    checkpoints[i]?.cp_answer !== "" &&
                    checkpoints[i]?.cp_answer !== undefined
                      ? checkpoints[i]?.cp_answer
                      : "-",
                  style: commonStyle,
                };
                rows[i][dataKey2] = {
                  value:
                    Value[j]?.next_run !== "" &&
                    Value[j]?.next_run !== undefined
                      ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                      : "-",

                  style: commonStyle,
                };
              }
            }
          };
          if (j % 4 === 0) {
            const checkpoints =
              Value[j]?.maintenance_activities[0]?.checkpoints || [];
            const checkpointCount = checkpoints.length;
            const commonStyle = { textAlign: "center", colSpan: 2 };

            // Set the common properties for rows[0]
            rows[0]["data"] = {
              value: index,
              style: {
                textAlign: "center",
                colSpan: 1,
                rowSpan: checkpointCount,
              },
            };
            rows[0]["data1"] = {
              value: key,
              style: {
                textAlign: "center",
                colSpan: 2,
                rowSpan: checkpointCount,
              },
            };
            rows[0]["data2"] = {
              value: checkpoints[0]?.checkpoint,
              style: commonStyle,
            };
            rows[0]["data3"] = {
              value:
                checkpoints[0]?.cp_answer !== "" &&
                checkpoints[0]?.cp_answer !== undefined
                  ? checkpoints[0]?.cp_answer
                  : "-",
              style: commonStyle,
            };
            rows[0]["data4"] = {
              value:
                Value[j]?.next_run !== "" && Value[j]?.next_run !== undefined
                  ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                  : "-",
              style: commonStyle,
            };

            // Set properties for additional rows
            for (let i = 1; i < 10; i++) {
              if (i < checkpointCount) {
                rows[i]["data2"] = {
                  value:
                    checkpoints[i]?.checkpoint !== "" &&
                    checkpoints[i]?.checkpoint !== undefined
                      ? checkpoints[i]?.checkpoint
                      : "-",
                  style: commonStyle,
                };
                rows[i]["data3"] = {
                  value:
                    checkpoints[i]?.cp_answer !== "" &&
                    checkpoints[i]?.cp_answer !== undefined
                      ? checkpoints[i]?.cp_answer
                      : "-",
                  style: commonStyle,
                };
                rows[i]["data4"] = {
                  value:
                    Value[j]?.next_run !== "" &&
                    Value[j]?.next_run !== undefined
                      ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                      : "-",
                  style: commonStyle,
                };
              }
            }
          } else if (j % 4 === 1) {
            processCheckpoints("data5", "data6");
          } else if (j % 4 === 2) {
            processCheckpoints("data7", "data8");
          } else if (j % 4 === 3) {
            processCheckpoints("data9", "data10");
          }
        }
        index++;
        arrayresponse.push(
          ...rows.filter((row) => Object.keys(row).length !== 0)
        );
      });
      resDATA.push(arrayresponse);
    }
  } else {
    responseData?.maint_checklist?.forEach((el) => {
      (obj[el?.asset_name] ??= []).push(el);
    });

    const firstKey = Object.keys(obj)[0];
    const firstValue = Math.ceil(obj[firstKey]?.length / 4);

    for (let i = 0; i < firstValue; i++) {
      let start = i * 4;
      let end = (i + 1) * 4;
      let index = 1;
      let arrayresponse = [];
      let headingcount = 0;
      Object.keys(obj).forEach((key) => {
        const Value = obj[key];
        let rows = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        if (headingcount == 0) {
          const startMonth =
            obj[key][start]?.maint_sch_date !== "" &&
            obj[key][start]?.maint_sch_date !== undefined
              ? moment(obj[key][start]?.maint_sch_date)
                  .local()
                  .format("MMMM")
                  .toUpperCase()
              : "";
          const endMonth =
            obj[key][end]?.maint_sch_date !== "" &&
            obj[key][end]?.maint_sch_date !== undefined
              ? moment(obj[key][end]?.maint_sch_date)
                  .local()
                  .format("MMMM")
                  .toUpperCase()
              : "";
          headings.push({
            data: `${chklname} FOR THE MONTH OF ${
              startMonth === endMonth || endMonth == ""
                ? startMonth
                : `${startMonth} - ${endMonth}`
            }`,
            colSpan: "6",
          });
        }

        headingcount++;

        for (let j = start; j < end; j++) {
          const processCheckpoints = (dataKey1, dataKey2) => {
            const checkpoints =
              Value[j]?.maintenance_activities[0]?.checkpoints || [];
            const checkpointCount =
              Value[0]?.maintenance_activities[0]?.checkpoints.length;
            // console.log("checkpointCount",checkpointCount)

            const commonStyle = { textAlign: "center", colSpan: 2 };

            // Set the properties for rows[0]
            rows[0][dataKey1] = {
              value:
                checkpoints[0]?.cp_answer !== "" &&
                checkpoints[0]?.cp_answer !== undefined
                  ? checkpoints[0]?.cp_answer
                  : "-",
              style: commonStyle,
            };
            rows[0][dataKey2] = {
              value:
                Value[j]?.next_run !== "" && Value[j]?.next_run !== undefined
                  ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                  : "-",
              style: commonStyle,
            };
            // Set properties for additional rows
            for (let i = 1; i < 10; i++) {
              if (i < checkpointCount) {
                rows[i][dataKey1] = {
                  value:
                    checkpoints[i]?.cp_answer !== "" &&
                    checkpoints[i]?.cp_answer !== undefined
                      ? checkpoints[i]?.cp_answer
                      : "-",
                  style: commonStyle,
                };
                rows[i][dataKey2] = {
                  value:
                    Value[j]?.next_run !== "" &&
                    Value[j]?.next_run !== undefined
                      ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                      : "-",

                  style: commonStyle,
                };
              }
            }
          };
          if (j % 4 === 0) {
            const checkpoints =
              Value[j]?.maintenance_activities[0]?.checkpoints || [];
            const checkpointCount = checkpoints.length;
            const commonStyle = { textAlign: "center", colSpan: 2 };

            // Set the common properties for rows[0]
            rows[0]["data"] = {
              value: index,
              style: {
                textAlign: "center",
                colSpan: 1,
                rowSpan: checkpointCount,
              },
            };
            rows[0]["data1"] = {
              value: Value[j]?.asset_name,
              style: {
                textAlign: "center",
                colSpan: 2,
                rowSpan: checkpointCount,
              },
            };
            rows[0]["data2"] = {
              value: checkpoints[0]?.checkpoint,
              style: commonStyle,
            };
            rows[0]["data3"] = {
              value:
                checkpoints[0]?.cp_answer !== "" &&
                checkpoints[0]?.cp_answer !== undefined
                  ? checkpoints[0]?.cp_answer
                  : "-",
              style: commonStyle,
            };
            rows[0]["data4"] = {
              value:
                Value[j]?.next_run !== "" && Value[j]?.next_run !== undefined
                  ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                  : "-",
              style: commonStyle,
            };

            // Set properties for additional rows
            for (let i = 1; i < 10; i++) {
              if (i < checkpointCount) {
                rows[i]["data2"] = {
                  value:
                    checkpoints[i]?.checkpoint !== "" &&
                    checkpoints[i]?.checkpoint !== undefined
                      ? checkpoints[i]?.checkpoint
                      : "-",
                  style: commonStyle,
                };
                rows[i]["data3"] = {
                  value:
                    checkpoints[i]?.cp_answer !== "" &&
                    checkpoints[i]?.cp_answer !== undefined
                      ? checkpoints[i]?.cp_answer
                      : "-",
                  style: commonStyle,
                };
                rows[i]["data4"] = {
                  value:
                    Value[j]?.next_run !== "" &&
                    Value[j]?.next_run !== undefined
                      ? moment(Value[j]?.next_run).local().format("DD/MM/YY")
                      : "-",
                  style: commonStyle,
                };
              }
            }
          } else if (j % 4 === 1) {
            processCheckpoints("data5", "data6");
          } else if (j % 4 === 2) {
            processCheckpoints("data7", "data8");
          } else if (j % 4 === 3) {
            processCheckpoints("data9", "data10");
          }
        }
        index++;
        arrayresponse.push(
          ...rows.filter((row) => Object.keys(row).length !== 0)
        );
      });
      resDATA.push(arrayresponse);
    }
  }

  useEffect(() => {
    setResData(responseData);
  }, [responseData]);

  return (
    <div>
      {resDATA?.length > 0 ? (
        resDATA?.map((el, i) => {
          return (
            <div
              key={i}
              style={{
                pageBreakBefore: i !== 0 ? "always" : "auto",
                paddingTop: i !== 0 ? "20px" : "0px",
              }}
            >
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={headings[i]}
                custLogo={resData?.customer?.cust_logo}
              />
              <ReportTable
                headerData2={tableHeaders}
                headerData={headingName2}
                verticalBodyData={el}
              />
            </div>
          );
        })
      ) : (
        <>
          <ReportHearder
            data1={data1}
            data2={data2}
            data3={data3}
            custLogo={resData?.customer?.cust_logo}
          />
          <ReportTable headerData2={tableHeaders} headerData={headingName2} />
        </>
      )}
    </div>
  );
}

export default Filtertemplate;
