import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function PCAUBI({ responseData, filterData, chklname, docNumber, depname, checkedby, instrumentCode, instCodeData, objheading, malur, medopharm, check1, check2, checked }) {

    const formatValue = (condition, trueValue, falseValue = '-') => {
       return condition ? trueValue : falseValue
    };
    const formatCheckpoint = (objectIndex,index) => formatValue(
        responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[objectIndex]?.checkpoints[index]?.answer !== undefined,
        responseData?.checklist && responseData?.checklist?.length > 0 && responseData.checklist[objectIndex]?.checkpoints[index]?.answer!==''? responseData.checklist[objectIndex]?.checkpoints[index]?.answer : '-'
    );
    const formatCheckpointMaint = (objectIndex,index) => formatValue(
        responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer !== undefined,
        responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer!==''? responseData.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer : '-'
    );

    const formatParent = (field) => formatValue(
        responseData?.parent && responseData?.parent[field] !== undefined,
        responseData?.parent ? responseData.parent[field] : ''
    );

    let resData = [];
    let InspectionDataArray = responseData?.checklist?.length>0? responseData?.checklist :[{}]
    let MaintenanceDataArray = responseData?.maint_checklist?.length>0? responseData?.maint_checklist :[{}]
    const data3 = {
        data: `${chklname}`,
        colSpan: '7'
    }
    if (docNumber == "QCML/035:F2-00") {
        InspectionDataArray?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] = [
                {
                    response: [
                        {
                            data: { value: "Date of analysis", style: { textAlign: 'left' } },
                            data1: {
                                value: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.start_date !== '',
                                    moment(responseData?.checklist[index]?.start_date).local().format("DD/MM/YY")
                                ) : '-',
                                style: { textAlign: 'left', colSpan: 5 }
                            }
                        },
                        {
                            data: { value: "Autoclave ID no.", style: { textAlign: 'left' } },
                            data1: { value: formatParent('parent_id'), style: { textAlign: 'left', colSpan: 5 } }
                        },
                        {
                            data: { value: "Location", style: { textAlign: 'left' } },
                            data1: { value: formatParent('location'), style: { textAlign: 'left', colSpan: 5 } }
                        },
                        {
                            data: { value: "Make & Model", style: { textAlign: 'left' } },
                            data1: {
                                value: `${formatParent('make')}  ${formatParent('model')}`,
                                style: { textAlign: 'left', colSpan: 5 }
                            }
                        },
                        {
                            data: { value: "Biological indicator used", style: { textAlign: 'left' } },
                            data1: { value: formatCheckpoint(index,0), style: { textAlign: 'left', colSpan: 5 } }
                        },
                        {
                            data: { value: "BI manufacturer", style: { textAlign: 'left' } },
                            data1: { value: formatCheckpoint(index,1), style: { textAlign: 'left', colSpan: 2 } },
                            data2: { value: "Population", style: { textAlign: 'left' } },
                            data3: { value: formatCheckpoint(index,2), style: { textAlign: 'left', colSpan: 2 } }
                        },
                        {
                            data: { value: "BI lot no", style: { textAlign: 'left' } },
                            data1: { value: formatCheckpoint(index,3), style: { textAlign: 'left', colSpan: 1 } },
                            data2: { value: "Mfg. Date", style: { textAlign: 'left' } },
                            data3: { value: formatCheckpoint(index,4), style: { textAlign: 'left', colSpan: 1 } },
                            data4: { value: "Exp. Date", style: { textAlign: 'left' } },
                            data5: { value: formatCheckpoint(index,5), style: { textAlign: 'left', colSpan: 1 } }
                        },
                        {
                            data: { value: "Sterilization time & temperature", style: { textAlign: 'left' } },
                            data1: {
                                value: responseData?.checklist && responseData?.checklist?.length > 0 ? `${formatCheckpoint(index,6)} ${formatValue(
                                    responseData?.checklist && responseData?.checklist[index]?.checkpoints[7]?.answer !== '',
                                    `& ${responseData?.checklist[index]?.checkpoints[7].answer}`
                                )}` : '-',
                                style: { textAlign: 'left', colSpan: 5 }
                            }
                        },
                        {
                            data: { value: "Incubation time & temperature", style: { textAlign: 'left' } },
                            data1: {
                                value: responseData?.checklist && responseData?.checklist?.length > 0 ? `${formatCheckpoint(index,8)} ${formatValue(
                                    responseData?.checklist && responseData?.checklist[index]?.checkpoints[9]?.answer !== '',
                                    `& ${responseData?.checklist[index]?.checkpoints[9].answer}`
                                )}` : "-",
                                style: { textAlign: 'left', colSpan: 5 }
                            }
                        },
                    ]
                },
                {
                    header: [
                        { data: "Sample Identification", colSpan: 1, rowSpan: 2, textAlign: 'center' },
                        { data: "Observation", colSpan: 2, rowSpan: 1, textAlign: 'center' },
                        { data: "Remarks", colSpan: 1, rowSpan: 2, textAlign: 'center' }
                    ],
                    header2: [
                        { data: "24hrs", colSpan: 1, rowSpan: 1, textAlign: 'center' },
                        { data: "48hrs", colSpan: 1, rowSpan: 1, textAlign: 'center' }
                    ],
                    response: [
                        {
                            data: { value: "Vial-1 (Top basket)", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,10), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,11), style: { textAlign: 'center' } },
                            data3: {value: responseData?.checklist && responseData?.checklist?.length > 0? (formatCheckpoint(index,12) === formatCheckpoint(index,13) ? formatCheckpoint(index,12): `${formatCheckpoint(index,12)} ${formatCheckpoint(index,13)}`): '-',style: { textAlign: 'center' }}
                        },
                        {
                            data: { value: "Vial-2 (Bottom basket)", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,14), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,15), style: { textAlign: 'center' } },
                            data3: {value: responseData?.checklist && responseData?.checklist?.length > 0? (formatCheckpoint(index,16) === formatCheckpoint(index,17) ? formatCheckpoint(index,16): `${formatCheckpoint(index,16)} ${formatCheckpoint(index,17)}`): '-',style: { textAlign: 'center' }}
                        },
                        {
                            data: { value: "Positive control", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,18), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,19), style: { textAlign: 'center' } },
                            data3: {value: responseData?.checklist && responseData?.checklist?.length > 0? (formatCheckpoint(index,20) === formatCheckpoint(index,21) ? formatCheckpoint(index,20): `${formatCheckpoint(index,20)} ${formatCheckpoint(index,21)}`): '-',style: { textAlign: 'center' }}
                        },
                        {
                            data: { value: "Negative control", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,22), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,23), style: { textAlign: 'center' } },
                            data3: {value: responseData?.checklist && responseData?.checklist?.length > 0? (formatCheckpoint(index,24) === formatCheckpoint(index,25) ? formatCheckpoint(index,24): `${formatCheckpoint(index,24)} ${formatCheckpoint(index,25)}`): '-',style: { textAlign: 'center' }}
                        },
                        {
                            data: {
                                value: `Result : The efficiency of autoclave ${formatCheckpoint(index,26)}`,
                                style: { textAlign: 'left', colSpan: 4 }
                            }
                        }
                    ]
                }
            ]
            valueArray['footer'] =[
                [
                    { data: "Done by :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist?.[index]?.performed_by && responseData?.checklist?.[index]?.performed_by!=='' , responseData?.checklist[index]?.performed_by) : '-', colSpan: 4, rowSpan: 9, textAlign: 'left' },
                    { data: "Checked by :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist?.[index]?.ins_approval_id && responseData?.checklist?.[index]?.ins_approval_id!=='', responseData?.checklist[index]?.ins_approval_id) : '-', colSpan: 4, rowSpan: 9, textAlign: 'left' },
                ],
                [
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_completed_date ? moment(responseData?.checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.start_date !== '',
                    moment(responseData?.checklist[index]?.start_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    } else if (docNumber == "QAD/050:F1-00") {
        InspectionDataArray?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] = [
                {
                    header: [
                        { data: `SET RPM`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `OBSERVED VALUE BY TACHO METER`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `LIMIT`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: 20, style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,0), style: { textAlign: 'center' } },
                            data2: { value: "± 1 rpm of the set value", style: { textAlign: 'center', rowSpan: 3 } },
                        },
                        {
                            data: { value: 25, style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,1), style: { textAlign: 'center' } },
                        },
                        {
                            data: { value: 30, style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,2), style: { textAlign: 'center' } },
                        }
                    ]
                }
            ]
            valueArray['footer'] =[
                [
                    { data: `Remarks`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: formatCheckpoint(index,3)!=='-'? `Instrument is ${formatCheckpoint(index,3)} for use` : '-', colSpan: '11', rowSpan: "9", textAlign: 'left' },
        
                ],[
                    { data: `Calibrated by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by ? responseData?.checklist[index]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `Checked by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_approval_id ? responseData?.checklist[index]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ], [
                    { data: `Date :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `Date :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_completed_date ? moment(responseData?.checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
        
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.start_date !== '',
                    moment(responseData?.checklist[index]?.start_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            valueArray['tableHeaders'] = [[
                { data: `Instrument Name:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'Automated Friabilator(EF-2W)'}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
    
            ], [
                { data: `Instrument ID No.:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-'}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
    
            ], [
                { data: `Frequency of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${"Monthly."}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
    
            ], [
                { data: `Acceptable Limit:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'± 1 rpm'}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
    
            ], [
                { data: `Date of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${responseData?.checklist?.length > 0 && responseData?.checklist[index]?.start_date && responseData?.checklist[index]?.start_date !== '' ? moment(responseData?.checklist[index]?.start_date).local().format("DD/MM/YY") : '-'}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
    
            ]]
            resData.push(valueArray);
        });
    } else if (docNumber == "PDN/058:F1-01") {
        InspectionDataArray?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Set Temperature`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Temperature of Bath by External Probe of instrument`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Temperature of Beaker by External Probe of instrument`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated External Thermometer Reading of Bath`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated External Thermometer Reading of Beaker`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "35.0°C", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,0), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,1), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,2), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,3), style: { textAlign: 'center' } },
                        },
                        {
                            data: { value: "37.0°C", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,4), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,5), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,6), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,7), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    header: [
                        { data: `Strokes Testing`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `Strokes`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Timer 1`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Timer 2`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "Number of strokes", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,8), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,10), style: { textAlign: 'center' } },
                            data3: { value: "29 to 32 strokes per min", style: { textAlign: 'center' } },
                        },
                        {
                            data: { value: "Length of strokes", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,9), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,11), style: { textAlign: 'center' } },
                            data3: { value: "55 ± 2 mm", style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    header: [
                        { data: `Timer Testing`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `Time Interval`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `1 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `10 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `20 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `30 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `40 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "Observation for Timer 1", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,12), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,14), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,16), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,18), style: { textAlign: 'center' } },
                            data5: { value: formatCheckpoint(index,20), style: { textAlign: 'center' } },
                            data6: { value: "30 seconds", style: { textAlign: 'center', rowSpan: 2 } },
                        },
                        {
                            data: { value: "Observation for Timer 2", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,13), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,15), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,17), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,19), style: { textAlign: 'center' } },
                            data5: { value: formatCheckpoint(index,21), style: { textAlign: 'center' } },
                        },
                    ]
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `Remarks`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: formatCheckpoint(index,22)!=='-'?`Instrument is ${formatCheckpoint(index,22)} for use:`:'-', colSpan: '11', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: `Next Calibrated Due On:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${formatParent('next_run') !== '-' ? moment(responseData?.parent?.next_run).local().format("DD/MM/YY") : '-'}`, colSpan: '11', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: `Done by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by !== undefined, responseData?.checklist[index]?.performed_by, '-') : '-', colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `Checked by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_approval_id !== undefined, responseData?.checklist[index]?.ins_approval_id, '-') : '-', colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: `Date :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `Date :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_completed_date ? moment(responseData?.checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.start_date !== '',
                    moment(responseData?.checklist[index]?.start_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            valueArray['tableHeaders'] = [[
                { data: `Instrument Name:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Disintegration Test Apparatus`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Instrument ID No :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${formatParent('parent_id')}`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Frequency of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Monthly.`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Date of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: responseData?.checklist && responseData?.checklist?.length > 0 ? `${responseData?.checklist ? moment(responseData?.checklist[index]?.start_date).local().format("DD/MM/YY") : '-'}` : '-', colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Temperature Testing`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Acceptable Limit ± 1.0°C`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ]]
            resData.push(valueArray);
        });
    } else if (docNumber == "QCCB/036:F1-01") {
        let checkpoints = [
            { value: "1", weight: "0.01000 g", range: "0.00990 gm to 0.01010 gm" },
            { value: "2", weight: "0.10000 g", range: "0.09989 gm to 0.10009 gm" },
            { value: "3", weight: "1.00000 g", range: "0.99900 gm to 1.00100 gm" },
            { value: "4", weight: "5.00000 g", range: "4.99495 gm to 5.000495 gm" },
            { value: "5", weight: "50.0000 g", range: "49.94997 gm to 50.04997 gm" },
            { value: "6", weight: "100.0000 g", range: "99.8999 gm to 100.0999 gm" },
            { value: "7", weight: "200.0000 g", range: "199.8000 gm to 200.2000 gm" },
        ];
        responseData?.checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `S.no`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Standard weights`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed weights`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Acceptance criteria (± 0.10% deviation)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: checkpoints.map((checkpoint, i) => ({
                        data: {
                            value: checkpoint.value,
                            style: { textAlign: 'center', colSpan: '1' },
                        },
                        data1: {
                            value: checkpoint.weight,
                            style: { textAlign: 'center', colSpan: '4' },
                        },
                        data2: {
                            value: responseData?.checklist !== undefined && responseData?.checklist[index]?.checkpoints[i] && responseData?.checklist[index]?.checkpoints[i].answer !== "" ? responseData?.checklist[index]?.checkpoints[i]?.answer : '-',
                            style: { textAlign: 'center', colSpan: '4' },
                        },
                        data3: {
                            value: checkpoint.range,
                            style: { textAlign: 'center', colSpan: '4' },
                        },
                    }))
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `Tolerance : ±10 % of standard weight used or least count whichever is maximum`, rowSpan: "1", textAlign: 'left' },
                ], [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                ], [
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by !== undefined ? responseData?.checklist[index]?.performed_by : '-'}`, colSpan: '3', rowSpan: "9", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by !== undefined ? responseData?.checklist[index]?.performed_by : '-'}`, colSpan: '3', rowSpan: "9", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_approval_id !== undefined ? responseData?.checklist[index]?.ins_approval_id : '-'}`, colSpan: '3', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE DATE OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.ins_sch_date !== '',
                    moment(responseData?.checklist[index]?.ins_sch_date).local().format("DD/MM/YYYY").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            valueArray['tableHeaders'] = [[
                { data: `Calibration SOP No :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'QCCB|036|R1'}`, colSpan: '11', rowSpan: "1", textAlign: 'left' },
    
            ], [
                { data: `INSTRUMENT NAME :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'SEMI MICRO BALANCE'}`, colSpan: '11', rowSpan: "9", textAlign: 'left' },
    
            ], [
                { data: `Location :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${responseData?.parent !== undefined ? responseData?.parent?.location : '-'}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
                { data: `Least count :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `0.00001 gm`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
    
            ], [
                { data: `Actual Range :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${"1 mg to 220 g"}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
                { data: `Operating Range :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${"10 mg to 200 g"}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
    
            ], [
                { data: `Model No :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `SECURA225D-10IN`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
                { data: `Instrument Code No :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-'}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
    
            ], [
                { data: `Frequency :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'Daily'}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
                { data: `Std. weight certificate No : & Date `, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `${'-'}`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
    
            ], [
                { data: `Level spirit check :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `OK`, colSpan: '11', rowSpan: "1", textAlign: 'left' },
    
            ]]
            resData.push(valueArray);
        });
    } else if (docNumber == "PDN/161:F1-00") {
        InspectionDataArray?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Set Temperature`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Temperature of Jar by External Probe A instrument`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Temperature of Jar by External Probe B instrument`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated External Thermometer Reading of Jar A`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated External Thermometer Reading of Jar B`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "35.0°C", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,0), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,1), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,2), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,3), style: { textAlign: 'center' } },
                        },
                        {
                            data: { value: "37.0°C", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,4), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,5), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,6), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,7), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    header: [{ data: `STROKES TESTING:`, colSpan: '1', rowSpan: "2", textAlign: 'left' }]
                },
                {
                    header: [
                        { data: `STROKES`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `BASKET A (RUN/HALT)`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `BASKET B (RUN/HALT)`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `LIMIT`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "Number of strokes", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,8), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,10), style: { textAlign: 'center' } },
                            data3: { value: "29 to 32 strokes per min", style: { textAlign: 'center' } },
                        },
                        {
                            data: { value: "Length of strokes", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,9), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,11), style: { textAlign: 'center' } },
                            data3: { value: "55 ± 2 mm", style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    header: [{ data: `TIMER TESTING:`, colSpan: '1', rowSpan: "2", textAlign: 'left' }]
                },
                {
                    header: [
                        { data: `Time Interval`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `1 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `10 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `20 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `30 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `40 min`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "Observation for ARM A", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,12), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,14), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,16), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,18), style: { textAlign: 'center' } },
                            data5: { value: formatCheckpoint(index,20), style: { textAlign: 'center' } },
                            data6: { value: "±30 seconds", style: { textAlign: 'center', rowSpan: 2 } },
                        },
                        {
                            data: { value: "Observation for ARM B", style: { textAlign: 'center' } },
                            data1: { value: formatCheckpoint(index,13), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpoint(index,15), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpoint(index,17), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpoint(index,19), style: { textAlign: 'center' } },
                            data5: { value: formatCheckpoint(index,21), style: { textAlign: 'center' } },
                        },
                    ]
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `Remarks`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: formatCheckpoint(index,3), colSpan: '11', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: `Next Calibrated Due On:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: formatValue(responseData?.parent?.next_run, moment(responseData?.parent?.next_run).local().format("DD/MM/YY")), colSpan: '11', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: `Done by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist?.[index]?.performed_by, responseData.checklist[index].performed_by):'-', colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `Checked by :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: responseData?.checklist && responseData?.checklist?.length > 0 ?formatValue(responseData?.checklist?.[index]?.ins_approval_id, responseData.checklist[index].ins_approval_id):'-', colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ], [
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_completed_date ? moment(responseData?.checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE DATE OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.ins_sch_date !== '',
                    moment(responseData?.checklist[index]?.ins_sch_date).local().format("DD/MM/YYYY").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            valueArray['tableHeaders'] = [[
                { data: `Instrument Name:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Disintegration Test Apparatus (EDI-2SA)`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Instrument ID No :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: formatParent('parent_id'), colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Frequency of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Monthly.`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `Date of Calibration:`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(responseData?.checklist, moment(responseData?.checklist[index]?.start_date).local().format("DD/MM/YY")):'-', colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ], [
                { data: `TEMPERATURE TESTING`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                { data: `Acceptable Limit ± 1.0°C`, colSpan: '12', rowSpan: "9", textAlign: 'left' },
            ]]
            resData.push(valueArray);
        });
    } else if (docNumber=="QCCB/033:F2-00"){
        MaintenanceDataArray?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Calibration Sop No.`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated on`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration due on`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Report no`, rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data1: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpointMaint(index,3), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: "Instrument name :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: "DIGITAL HARDNESS TESTER",style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data: { value: "Model no :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data2: {value: "Instrument code :",style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data1: {value: "Frequency :",style: { textAlign: 'left', colSpan: '4' }},
                            data2: {value: "Six Months once",style: { textAlign: 'left', colSpan: '12' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `1) FOR HARDNESS CALIBRATION`, rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: "Calibrated standard weight certificate No :",style: { textAlign: 'left', colSpan: '4' } },
                            data1: { value: formatCheckpointMaint(index,4), style: { textAlign: 'left', colSpan: '12' } },
                        },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Actual weights (Kg)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated standard weights (Kg)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed weights (Kg)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Acceptance Criteria (Kg)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: {value: "1",style: { textAlign: 'center', colSpan: '1', },},
                            data1: {value: "5.000 kg",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,5), style: { textAlign: 'center',colSpan:'4' } },
                            data3: { value: formatCheckpointMaint(index,6), style: { textAlign: 'center',colSpan:'4' } },
                            data4: {value: '4.950 kg to 5.050 kg',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,7), style: { textAlign: 'center',colSpan:'4' } },
                        },
                        {
                            data: {value: "2",style: { textAlign: 'center', colSpan: '1', },},
                            data1: {value: "5.000 kg",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,8), style: { textAlign: 'center',colSpan:'4' } },
                            data3: { value: formatCheckpointMaint(index,9), style: { textAlign: 'center',colSpan:'4' } },
                            data4: {value: '4.950 kg to 5.050 kg',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,10), style: { textAlign: 'center',colSpan:'4' } },
                        },
                        {
                            data: {value: "3",style: { textAlign: 'center', colSpan: '1', },},
                            data1: {value: "5.000 kg",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,11), style: { textAlign: 'center',colSpan:'4' } },
                            data3: { value: formatCheckpointMaint(index,12), style: { textAlign: 'center',colSpan:'4' } },
                            data4: {value: '4.950 kg to 5.050 kg',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,13), style: { textAlign: 'center',colSpan:'4' } },
                        },
                        {
                            data: {value: "4",style: { textAlign: 'center', colSpan: '1', },},
                            data1: {value: "5.000 kg",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,14), style: { textAlign: 'center',colSpan:'4' } },
                            data3: { value: formatCheckpointMaint(index,15), style: { textAlign: 'center',colSpan:'4' } },
                            data4: {value: '4.950 kg to 5.050 kg',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,16), style: { textAlign: 'center' ,colSpan:'4'} },
                        },
                        {
                            data: {value: "5",style: { textAlign: 'center', colSpan: '1', },},
                            data1: {value: "5.000 kg",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,17), style: { textAlign: 'center',colSpan:'4' } },
                            data3: { value: formatCheckpointMaint(index,18), style: { textAlign: 'center',colSpan:'4' } },
                            data4: {value: '4.950 kg to 5.050 kg',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,19), style: { textAlign: 'center' ,colSpan:'4'} },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: `INFERENCE ${formatCheckpointMaint(index,20)}`}
                        }
                    ]
                },
                {
                    header: [
                        { data: `2) FOR DIAMETER CALIBRATION :`, rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "Calibrated Standard Gauge Certificate No :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: { value: formatCheckpointMaint(index,21), style: { textAlign: 'left', colSpan: '12' } },
                        },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Actual Gauge Daimeter (mm)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated standard Gauge Daimeter (mm)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed Gauge Daimeter (mm)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Acceptance Criteria (mm)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: {value: "1",style: { textAlign: 'center', colSpan: '1', }},
                            data1: {value: "25.00 mm",style: { textAlign: 'center', colSpan: '4', },},
                            data2: { value: formatCheckpointMaint(index,22), style: { textAlign: 'center', colSpan: '4', }},
                            data3: { value: formatCheckpointMaint(index,23), style: { textAlign: 'center', colSpan: '4', }},
                            data4: {value: '24.90 mm to 25.10 mm',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,24), style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: {value: "2",style: { textAlign: 'center', colSpan: '1', }},
                            data1: {value: "25.00 mm",style: { textAlign: 'center', colSpan: '4', },},
                            data2: { value: formatCheckpointMaint(index,25), style: { textAlign: 'center', colSpan: '4', }},
                            data3: { value: formatCheckpointMaint(index,26), style: { textAlign: 'center', colSpan: '4', }},
                            data4: {value: '24.90 mm to 25.10 mm',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,27), style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: {value: "3",style: { textAlign: 'center', colSpan: '1', }},
                            data1: {value: "25.00 mm",style: { textAlign: 'center', colSpan: '4', },},
                            data2: { value: formatCheckpointMaint(index,28), style: { textAlign: 'center', colSpan: '4', }},
                            data3: { value: formatCheckpointMaint(index,29), style: { textAlign: 'center', colSpan: '4', }},
                            data4: {value: '24.90 mm to 25.10 mm',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,30), style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: {value: "4",style: { textAlign: 'center', colSpan: '1', }},
                            data1: {value: "25.00 mm",style: { textAlign: 'center', colSpan: '4', },},
                            data2: { value: formatCheckpointMaint(index,31), style: { textAlign: 'center', colSpan: '4', }},
                            data3: { value: formatCheckpointMaint(index,32), style: { textAlign: 'center', colSpan: '4', }},
                            data4: {value: '24.90 mm to 25.10 mm',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,33), style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: {value: "5",style: { textAlign: 'center', colSpan: '1', }},
                            data1: {value: "25.00 mm",style: { textAlign: 'center', colSpan: '4', },},
                            data2: { value: formatCheckpointMaint(index,34), style: { textAlign: 'center', colSpan: '4', }},
                            data3: { value: formatCheckpointMaint(index,35), style: { textAlign: 'center', colSpan: '4', }},
                            data4: {value: '24.90 mm to 25.10 mm',style: { textAlign: 'center', colSpan: '4', },},
                            data5: { value: formatCheckpointMaint(index,36), style: { textAlign: 'center', colSpan: '4', }},
                        },
                    ]
                },
                {
                    response: [
                        { data: {value: `INFERENCE ${formatCheckpointMaint(index,37)}`}}
                    ]
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[0]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ], [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
        
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });        
    } else if (docNumber=="QCCB/021:F1-04"){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Calibration Sop No.`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated on`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration due`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Report No`, rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data1: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center' } },
                            data4: { value: formatCheckpointMaint(index,3), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "INSTRUMENT NAME :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: { value: "TAP DENSITY TESTER",style: { textAlign: 'left', colSpan: '12' },},
                        },
                        {
                            data: { value: "Location :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: { value: responseData?.parent !== undefined ? responseData?.parent?.location : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data2: { value: "Frequency :", style: { textAlign: 'left', colSpan: '4' }},
                            data3: { value: "2 month once", style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: { value: "Model No. :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: { value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data3: { value: "Instrument Code No. :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: { value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: { value: "Details :",style: { textAlign: 'left', colSpan: '4' }},
                            data2: { value: "NA",style: { textAlign: 'left', colSpan: '12' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `100 STROKES:`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Set Strokes`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed Strokes`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Limit`, colSpan: '4', rowSpan: "2", rowSpan: "2", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '4', rowSpan: "2", rowSpan: "2", textAlign: 'center' },
                    ],
                    header2: [
                        { data: `USP-I`, colSpan: '2', rowSpan: "1", textAlign: 'center' },
                        { data: `USP-II`, colSpan: '2', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "100",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,4), style: { textAlign: 'center' ,colSpan: '2',} },
                            data3: { value: formatCheckpointMaint(index,5), style: { textAlign: 'center' ,colSpan: '2',} },
                            data4: { value: '98-102',style: { textAlign: 'center', colSpan: '4', rowSpan: "3" }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "2.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "100",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,6), style: { textAlign: 'center',colSpan: '2' } },
                            data3: { value: formatCheckpointMaint(index,7), style: { textAlign: 'center',colSpan: '2' } },
                            data4: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "3.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "100",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,8), style: { textAlign: 'center',colSpan: '2' } },
                            data3: { value: formatCheckpointMaint(index,9), style: { textAlign: 'center',colSpan: '2' } },
                            data4: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `150 STROKES:`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Set Strokes`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed Strokes`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Limit`, colSpan: '4', rowSpan: "2", rowSpan: "2", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '4', rowSpan: "2", rowSpan: "2", textAlign: 'center' },
                    ],
                    header2: [
                        { data: `USP-I`, colSpan: '2', rowSpan: "1", textAlign: 'center' },
                        { data: `USP-II`, colSpan: '2', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "150",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,10), style: { textAlign: 'center',colSpan: '2' } },
                            data3: { value: formatCheckpointMaint(index,11), style: { textAlign: 'center',colSpan: '2' } },
                            data4: {value: '147-153',style: { textAlign: 'center', colSpan: '4', rowSpan: "3" }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "2.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "150",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,12), style: { textAlign: 'center',colSpan: '2' } },
                            data3: { value: formatCheckpointMaint(index,13), style: { textAlign: 'center',colSpan: '2' } },
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "3.0", style: { textAlign: 'center', colSpan: '1', }},
                            data1: { value: "150",style: { textAlign: 'center', colSpan: '4', }},
                            data2: { value: formatCheckpointMaint(index,14), style: { textAlign: 'center',colSpan: '2' } },
                            data3: { value: formatCheckpointMaint(index,15), style: { textAlign: 'center',colSpan: '2' } },
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.ma_comment : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: `Remarks : The Calibration Status of the instrument is found ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[16]?.cp_answer !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[16]?.cp_answer : '-'}`}
                        }
                    ]
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[index]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ],  [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    }else if (docNumber=="QCCB/027:F1-03"){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Calibration Sop No.`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated on`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration due`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Report No`, rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center' } },
                            data1: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpointMaint(index,3), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: "INSTRUMENT NAME :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: {value: "DISINTEGRATION TEST APPARATUS",style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data: {value: "Location :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: responseData?.parent !== undefined ? responseData?.parent?.location : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Model No. :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: {value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Instrument Code No. :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Details :",style: { textAlign: 'left', colSpan: '4' }},
                            data2: {value: formatCheckpointMaint(index,4),style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Calibration due on :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: formatCheckpointMaint(index,5),style: { textAlign: 'left', colSpan: '4' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `1. No. of Strokes per minute`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed Strokes`, colSpan: '12', rowSpan: "1", textAlign: 'center' },
                    ],
                    header2: [
                        { data: `Time (minutes)`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Right Side`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Left Side`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,6), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,7), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,8),style: { textAlign: 'center', colSpan: '3', }},
                            data5: { value: 'Limits : 29 to 32 Strokes / min.',style: { textAlign: 'center', colSpan: '3', rowSpan: "3" }},
                        },
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,9), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,10), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,11),style: { textAlign: 'center', colSpan: '3', }},
                        },
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,12), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,13), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,14),style: { textAlign: 'center', colSpan: '3', }},
                        }, 
                    ]
                },
                {
                    header: [
                        { data: `2. Temperature of the Medium`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                        { data: `Thermometer ID : ${formatCheckpointMaint(index,15)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ],
                    header2: [
                        { data: `Calibration Due Date : ${formatCheckpointMaint(index,16)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `Observed Temperature`, colSpan: '13', rowSpan: "1", textAlign: 'center' },
        
                    ],
                    header2: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Time (minutes)`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Right Side`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Left Side`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,17), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,18), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,19), style: { textAlign: 'center' ,colSpan: '3',} },
                            data5: {value: 'Limits : 37°C ± 2°C',style: { textAlign: 'center', colSpan: '3', rowSpan: "5" },}
                        }, 
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,20), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,21), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,22), style: { textAlign: 'center' ,colSpan: '3',} },
                        }, 
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,23), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,24), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,25), style: { textAlign: 'center' ,colSpan: '3',} },
                        }, 
                        {
                            data: { value: "4", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,26), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,27), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,28), style: { textAlign: 'center' ,colSpan: '3',} },
                        }, 
                        {
                            data: { value: "5", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,29), style: { textAlign: 'center' ,colSpan: '3',} },
                            data3: { value: formatCheckpointMaint(index,30), style: { textAlign: 'center' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,31), style: { textAlign: 'center' ,colSpan: '3',} },
                        }, 
                    ]
                },
                {
                    header: [
                        { data: `3. Distance Travelled`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                        { data: `Scale ID : ${formatCheckpointMaint(index,32)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ],
                    header2: [
                        { data: `Calibration Due Date : ${formatCheckpointMaint(index,33)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `Measure the distance between lowest & highest strokes in triplicate using a calibrated Measuring scale. Calculate the average`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `Observation Distance`, colSpan: '13', rowSpan: "1", textAlign: 'center' },
        
                    ],
                    header2: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Right Side`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Left Side`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,34), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,35),style: { textAlign: 'center' ,colSpan: '4',} },
                            data5: {value: 'Limits : 53 to 57mm',style: { textAlign: 'center', colSpan: '4', rowSpan: "3" },}
                        }, 
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,36), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,37),style: { textAlign: 'center' ,colSpan: '4',} },
                        }, 
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,38), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,39),style: { textAlign: 'center' ,colSpan: '4',} },
                        }, 
                        {
                            data: { value: "Average", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: formatCheckpointMaint(index,40), style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,41), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,42), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: `4. Verification of Timer`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                        { data: `Stop Watch ID : ${formatCheckpointMaint(index,43)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ],
        
                    header2: [
                        { data: `Calibration Due Date : ${formatCheckpointMaint(index,44)}`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Time`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Observation`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: "30 minutes", style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,45),style: { textAlign: 'center' ,colSpan: '4',} },
                            data5: {value: '30 minutes ± 1 minute',style: { textAlign: 'center', colSpan: '4', rowSpan: "1" },}
                        }, 
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: "60 minutes", style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: formatCheckpointMaint(index,46),style: { textAlign: 'center' ,colSpan: '4',} },
                            data5: {value: '60 minutes ± 1 minute',style: { textAlign: 'center', colSpan: '4', rowSpan: "1" },}
                        }, 
                        {
                            data: {
                                value: `Remarks : The Calibration Status of thee instrument is found ${formatCheckpointMaint(index,47)}`,
                                style: { textAlign: 'left', colSpan: '13', },
                            },
                        }
                    ]
                },
        
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[0]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ], [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
        
            ]
            resData.push(valueArray);
        });
    }else if (docNumber=='QCGN/013:F8-01'){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    response: [
                        { data: { value: "Instrument ID :", style: { textAlign: 'left', colSpan: '4' } }, 
                        data1: { value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-', style: { textAlign: 'left', colSpan: '12' } } },
                        { data: { value: "Date of Prevention Maintenance :", style: { textAlign: 'left', colSpan: '4' } }, 
                        data1: { value: responseData?.parent !== undefined ? responseData?.parent?.location : '-', style: { textAlign: 'left', colSpan: '12' } } },
                        { data1: { value: "Frequency :", style: { textAlign: 'left', colSpan: '4' } }, 
                        data2: { value: responseData?.parent !== undefined ? responseData?.parent?.model : '-', style: { textAlign: 'left', colSpan: '12' } } },
                        { data1: { value: "Next Due on :", style: { textAlign: 'left', colSpan: '4' } }, 
                        data2: { value: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.next_run && responseData?.maint_checklist[index]?.next_run !== '' ? moment(responseData?.maint_checklist[index]?.next_run).local().format("DD/MM/YY") : '-'} `, style: { textAlign: 'left', colSpan: '12' } } }
                    ]                
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Activity`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Observation`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                        { data: `Sign and Date`, colSpan: '3', rowSpan: "1", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1' } },
                            data2: { value: "Check Instrument ventilation and electrical safety and ensure whether it is good working condition", style: { textAlign: 'center', colSpan: '3' } },
                            data3: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center', colSpan: '3' } },
                            data4: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[0]?.cp_comments !== ''?responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[0]?.cp_comments : '-', style: { textAlign: 'center', colSpan: '3' } },
                            data5: { value: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'} ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'} `, style: { textAlign: 'center', colSpan: '3', } }
                        },                    
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1' } },
                            data2: { value: "Check Acids are kept in the sand in Fume hood", style: { textAlign: 'center', colSpan: '3' } },
                            data3: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center', colSpan: '3' } },
                            data4: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[1]?.cp_comments !== ''?responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[1]?.cp_comments : '-', style: { textAlign: 'center', colSpan: '3' } },
                            data5: { value: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'} ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'} `, style: { textAlign: 'center', colSpan: '3', } }
                        },
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1' } },
                            data2: { value: "Verification of Air velocity", style: { textAlign: 'center', colSpan: '3' } },
                            data3: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center', colSpan: '3' } },
                            data4: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[2]?.cp_comments !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[2]?.cp_comments : '-', style: { textAlign: 'center', colSpan: '3' } },
                            data5: { value: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'} ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'} `, style: { textAlign: 'center', colSpan: '3', } }
                        },                     
                        {
                            data: { value: `Comments : ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.ma_comment ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.ma_comment : '-'}`, style: { textAlign: 'left', colSpan: '13', } }
                        }                    
                    ]
                },
                
            ]
            valueArray['footer'] =[
                [
                    { data: "Performed by :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-', colSpan: 4, rowSpan: 9, textAlign: 'left' },
                    { data: "Reviewed by :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[index]?.ins_approval_id : '-', colSpan: 4, rowSpan: 9, textAlign: 'left' },
                ],
                [
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: "Date :", colSpan: 3, rowSpan: 1, textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    }else if (docNumber=="QCCB/025:F1-02"){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] = [
                {
                    header: [
                        { data: `Calibration Sop No.`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated on`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration due`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Report No.`, rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center' } },
                            data1: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpointMaint(index,3), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: "INSTRUMENT NAME :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: {value: "MELTING POINT APPARATUS",style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data: {value: "Location :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: responseData?.parent !== undefined ? responseData?.parent?.location : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Model No. :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: {value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Instrument Code No. :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Details :",style: { textAlign: 'left', colSpan: '4' }},
                            data2: {value: formatCheckpointMaint(index,4),style: { textAlign: 'left', colSpan: '12' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `Melting points of the following Standard Materials Checked`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `MATERIAL`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `OBSERVED MELTING POING`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `LIMITS`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `REMARKS`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: "Vanillin", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,5), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "81 - 84°C",style: { textAlign: 'center', colSpan: '4', }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[5]?.cp_comments!=='' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[5]?.cp_comments  : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Acetanilide', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,6), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "114 - 116°C",style: { textAlign: 'center', colSpan: '4', }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[6]?.cp_comments!=='' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[6]?.cp_comments  : '-',style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Sulfanilamide', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,7), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "164.5 - 166°C",style: { textAlign: 'center', colSpan: '4', }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[7]?.cp_comments!=='' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[7]?.cp_comments  : '-',style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: "4", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Caffeine', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,8), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "234 - 239°C",style: { textAlign: 'center', colSpan: '4', }},
                            data5: { value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[8]?.cp_comments!=='' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.checkpoints[8]?.cp_comments  : '-',style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: `Remarks : The performance of the instrument found to be ${formatCheckpoint(9)}`, style: { textAlign: 'left', colSpan: '17', }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `Material`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Batch number`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: {value: "Vanillin :",style: { textAlign: 'left', colSpan: '1' }},
                            data1: {value: formatCheckpointMaint(index,10),style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data: {value: "Acetanilide :",style: { textAlign: 'left', colSpan: '1' }},
                            data1: {value: formatCheckpointMaint(index,11),style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data: {value: "Sulfanilamide :",style: { textAlign: 'left', colSpan: '1' }},
                            data1: {value: formatCheckpointMaint(index,12),style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data: {value: "Caffeine :",style: { textAlign: 'left', colSpan: '1' }},
                            data1: {value: formatCheckpointMaint(index,13),style: { textAlign: 'left', colSpan: '4' }},
                        },
                    ]
                },
            ]
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[index]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ],  [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
        
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        })
    }else if (docNumber=="QCCB/029:F1-06"){
        responseData?.checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] = [
                {
                    response: [
                        {
                            data: {value: "Calibration SOP NO :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: {value: formatCheckpoint(index,0),style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data: {value: "INSTRUMENT NAME :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: {value: "SHIMADZU BALANCE",style: { textAlign: 'left', colSpan: '12' }},
                        },
                        {
                            data: {value: "Location :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: responseData?.parent !== undefined ? responseData?.parent?.location : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data2: {value: "Least count :",style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "0.0001 gm",style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Model No :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: {value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Operation Range :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: '100 mg to 200 g',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Frequency :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: {value: "Daily",style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Instrument Code No :",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data1: {value: "Level spirit check :",style: { textAlign: 'left', colSpan: '4' },},
                            data2: {value: formatCheckpoint(index,3),style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: "Std. weight certificate No & Date:",style: { textAlign: 'left', colSpan: '4' }},
                            data4: {value: `${formatCheckpoint(index,1)} & ${formatCheckpoint(index,2)}`,style: { textAlign: 'left', colSpan: '4' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                        { data: `Standard weights`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `OBSERVED weights`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Acceptance criteria`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: "0.1000 g", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,4), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "0.0999 gm to 0.1001 gm",style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: '1.0000 g', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,5), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "0.9990 gm  to 1.0010 gm",style: { textAlign: 'center', colSpan: '4', }},
                        },
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: '5.0000 g', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,6), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "4.9950 gm to 5.0050 gm",style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: "4", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: '50.0000 g', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,7), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "49.9500 gm to 50.0500 gm",style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: "5", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: '100.0000 g', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,8), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "99.9000 gm to 100.1000 gm",style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: "6", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: '200.0000 g', style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpoint(index,9), style: { textAlign: 'center' ,colSpan: '4',} },
                            data4: { value: "199.8000 gm to 200.2000 gm",style: { textAlign: 'center', colSpan: '4', }},
                        }, 
                        {
                            data: { value: `Tolerance : ±0.10 % of standard weight used or least count whichever is maximum`, style: { textAlign: 'left', colSpan: '13', }},
                        },
                    ]
                }
            ]
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by!==undefined && responseData?.checklist[index]?.performed_by!==''? responseData?.checklist[index]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.performed_by!==undefined && responseData?.checklist[index]?.performed_by!==''? responseData?.checklist[index]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_approval_id!==undefined && responseData?.checklist[index]?.ins_approval_id!==''? responseData?.checklist[index]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ], 
                [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.end_date ? moment(responseData?.checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.checklist !== undefined && responseData?.checklist[index]?.ins_completed_date ? moment(responseData?.checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
        
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE DATE OF ${responseData?.checklist && responseData?.checklist?.length > 0 ? formatValue(
                    responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[index] && responseData?.checklist[index]?.ins_sch_date !== '' && responseData?.checklist[index]?.ins_sch_date !== undefined,
                    moment(responseData?.checklist[index]?.ins_sch_date).local().format("DD/MM/YYYY").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    }

    // header details
    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }

    return (
        <div>
      {resData?.length > 0 ?
        resData?.map((el, i) => {
          return (
            <div key={i} style={{
                pageBreakBefore: i !== 0 ? "always" : "auto",
                paddingTop: i !== 0 ? "20px" : "0px",
              }}>
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={el?.data3}
                custLogo={responseData?.customer?.cust_logo}
                malur={malur}
                medopharm={medopharm}
              />
              {instrumentCode == true && (
                <ReportTable headerData2={instCodeData} />
              )}
              {el?.tableHeaders?.length > 0 && el?.tableHeaders?.map((ele, i) => {
                 return <ReportTable
                     headerData2={ele}
                 />
             })
             }
              {el?.data?.length > 0 &&
                el?.data?.map((ele, i) => {
                  return (
                    <>
                      <ReportTable
                        headerData={ele?.header}
                        headerData2={ele?.header2}
                      />
                      <ReportTable verticalBodyData={ele?.response} />
                    </>
                  );
                })}
              {el?.footer?.length > 0 &&
                el?.footer?.map((ele, i) => {
                  return <ReportTable headerData2={ele} />;
                })}
            </div>
          );
        }):<ReportHearder
        data1={data1}
        data2={data2}
        data3={data3}
        custLogo={responseData?.customer?.cust_logo}
        malur={malur}
        medopharm={medopharm}
      />}      
        </div>
    )
}
export default PCAUBI
