/* Feature - Form to update/modify asset details

Created by - Mona R
Updated by - Janaki J */

//importing dependencies and packages
import { useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ASSET_UPDATE } from "../../redux/actions/assetAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";
// import moment from "moment";

//Function to update asset
const AssetUpdate = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const options = ["1", "2", "3"];
  // To fetch details from redux
  const dispatch = useDispatch();
  const assetGetSingle = useSelector((state) => state.asset.assetGetSingle);
  const isLoading = useSelector((state) => state.asset.isLoading);
  const access = useSelector((state) => state.access.asset);
  const userid = useSelector((state) => state.auth.userid);
  const queryParams = new URLSearchParams(location.search);
  const screen = (queryParams.get('screen'))?true:false; 
  const [critic, setCritic] = useState("");
  const [data, setData] = useState({
    id: "",
    assetId: "",
    name: "",
    make: "",
    model: "",
    serial: "",
    rating: "",
    uom: "",
    qr: "",
    manufactureYear: '',
    installationYear: '',
    life: '',
    criticality: '',
    area: "",
    location: "",
    building: "",
    block: "",
    floor: "",
    wing: "",
    dept: "",
    accessibility: "",
    photo: "",
    remark: "",
    url: "",
    ticket_bearer:[],
  });
  const patterns = {
    idChars: /^([a-zA-Z0-9./@-]+[0-9()\s-]*)*[a-zA-Z0-9./@-]+$/,
    numChars: /^([0-9]+\s)*[0-9]+$/,
    rncChars: /^([a-zA-Z0-9./&-]+\s)*[a-zA-Z.,0-9./&-]+$/,
    areaChars: /^([(a-zA-Z)0-9-/@|#]+\s)*[a-zA-Z0-9-/@|#]+$/,
    makeChars: /^([(a-zA-Z)0-9-/@|#]+\s)*[(a-zA-Z)0-9-/@|#]+$/,
    deptChars: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
    uomChars: /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/
  };
  
  //client-side validation
  const [assetidErr, setAssetidErr] = useState("");
  const [assetnameErr, setAssetnameErr] = useState("");
  const [makeErr, setMakeErr] = useState("");
  const [modelErr, setModelErr] = useState("");
  const [rcErr, setRcErr] = useState("");
  const [uomErr, setUomErr] = useState("");
  const [criticErr, setCriticErr] = useState("");
  const [areaErr, setAreaErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [deptErr, setDeptErr] = useState("");
  // function for client side validation.
  const formValidation = () => {
    const assetidErr = {};
    const assetnameErr = {};
    const makeErr = {};
    const modelErr = {};
    const rcErr = {};
    const uomErr = {};
    const criticErr = {};
    const areaErr = {};
    const locationErr = {};
    const deptErr = {};
    let isValidCheck = true;

    if (!validateChars(data.assetId, patterns.idChars) ||!validateChars(data.name, patterns.idChars) || !validateChars(data.make, patterns.makeChars) || !validateChars(data.model, patterns.areaChars) || !validateChars(data.rating, patterns.rncChars) || !validateChars(data.unitofmeasurement, patterns.uomChars) || !validateChars(data.area, patterns.areaChars) || !validateChars(data.location, patterns.areaChars) || !validateChars(data.dept, patterns.deptChars) ){
      isValidCheck = false
      // setIsValid(false)
    }

    if (data.assetId.trim().length < 1 ) {
      assetidErr.assetidShort = "Please Enter Asset ID";
      isValidCheck = false
    }
    setAssetidErr(assetidErr);
    if (data.name.trim().length < 1) {
      assetnameErr.assetidShort = "Please Enter Asset name";
      isValidCheck = false
    }
    setAssetnameErr(assetnameErr);
    if (data.make.trim().length < 1) {
      makeErr.assetidShort = "Please Enter Make";
      isValidCheck = false
    }
    setMakeErr(makeErr);
    if (data.model.trim().length < 1) {
      modelErr.assetidShort = "Please Enter Model";
      isValidCheck = false
    }
    setModelErr(modelErr);

    if (data.rating.trim().length < 1) {
      rcErr.assetidShort = "Please Enter Rating";
      isValidCheck = false
    }
    setRcErr(rcErr);
    if (data.uom.trim().length < 1) {
      uomErr.assetidShort = "Please Enter UOM";
      isValidCheck = false
    }
    setUomErr(uomErr);

    if (data.criticality.trim().length < 1) {
      criticErr.critiShort = " Choose Criticality";
      isValidCheck = false
    }
    setCriticErr(criticErr);
    if (data.area.trim().length < 1) {
      areaErr.assetidShort = "Please Enter Area";
      isValidCheck = false
    }
    setAreaErr(areaErr);
    if (data.location.trim().length < 1) {
      locationErr.locationShort = "Please Enter Location";
      isValidCheck = false
    }
    setLocationErr(locationErr);
    if (data.dept.trim().length < 1) {
      deptErr.assetidShort = "Please Enter Department";
      isValidCheck = false
    }
    setDeptErr(deptErr);
    return isValidCheck;
  };


  const validation = () => {
    let isValidCheck = formValidation();
    return isValidCheck;
  };

  //Function to update asset details
  const updateAssetDetails = () => {
    let assetData = {
      asset_id: data.assetId,
      asset_name: data.name,
      make: data.make,
      model: data.model,
      serial_number: data.serial,
      rating_capacity: data.rating,
      uom: data.uom,
      code_used: data.qr,
      year_of_manufacture: data.manufactureYear ? Number(data.manufactureYear) : null,
      year_of_installation: data.installationYear ? Number(data.installationYear) : null,
      expected_life_in_month: data.life ? Number(data.life) : null,
      criticality: data.criticality ? Number(data.criticality) : null,
      area: data.area,
      location: data.location,
      building: data.building,
      block_tower: data.block,
      floor: data.floor,
      wing: data.wing,
      department: data.dept,
      accessibility: data.accessibility,
      asset_image: data.photo,
      remarks: data.remark,
      url: data.url,
      modified_by: userid,
      ticket_bearer:[]
    };
    let checkValidation = validation();
    
    if (checkValidation) {
      dispatch({
        type: ASSET_UPDATE,
        payload: [id, assetData],
      });

      navigate("/assetlist");
    }
    
  };

  // fuction to fetch asset ID from params
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "ASSET_GET_SINGLE",
        payload: id,
      });
    }
  };

  //functions for validation
  function validateChars(str, regex) {
    return regex.test(str);
  }
  
  // useEffects
  useEffect(() => {
    sendID();
  }, [id]);

  useEffect(() => {
    if (assetGetSingle != undefined && assetGetSingle != 0) {
      setData({
        assetId: assetGetSingle.asset_id,
        name: assetGetSingle.asset_name,
        make: assetGetSingle.make,
        model: assetGetSingle.model,
        serial: assetGetSingle.serial_number,
        rating: assetGetSingle.rating_capacity,
        uom: assetGetSingle.uom,
        qr: assetGetSingle.code_used,
        manufactureYear: assetGetSingle.year_of_manufacture,
        installationYear: assetGetSingle.year_of_installation,
        life: assetGetSingle.expected_life_in_month,
        criticality: assetGetSingle.criticality,
        area: assetGetSingle.area,
        location: assetGetSingle.location,
        building: assetGetSingle.building,
        block: assetGetSingle.block_tower,
        floor: assetGetSingle.floor,
        wing: assetGetSingle.wing,
        dept: assetGetSingle.department,
        accessibility: assetGetSingle.accessibility,
        asset_image: assetGetSingle.asset_image,
        photo: assetGetSingle.asset_image,
        remark: assetGetSingle.remarks,
        url: assetGetSingle?.url
      });
    }
  }, [assetGetSingle]);

  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
      ...(field === "assetId" && { isValidAssetID: value.length < 1 }),
    }));
  };

  return (
    <div>
      {isLoading && <Loader />} {/* {to show the Loading effect} */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 offset-md-12">
            <div className="AssetUpdate" style={{ paddingTop: "25px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the page from refreshing
                    // Handle the form submission logic here
                  }}
                >
                  <div className="formHeadings mb-2">Edit Asset</div>
                  <div className="row">
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="assetId"
                        className="form-control form-rounded"
                        maxLength="200"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("assetId", e.target.value);
                        }}
                        value={data.assetId}
                      />
                      {Object.keys(assetidErr).map((key) => {
                        if (!data.assetId) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {assetidErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.assetId, patterns.idChars) || data.assetId === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Asset ID
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        disabled={screen}
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("name", e.target.value);
                        }}
                        value={data.name}
                      />
                      {Object.keys(assetnameErr).map((key) => {
                        if (!data.name) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {assetnameErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.name, patterns.idChars) || data.name === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Asset name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Make<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="make"
                        className="form-control"
                        maxLength="200"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("make", e.target.value);
                        }}
                        value={data.make}
                      />
                      {Object.keys(makeErr).map((key) => {
                        if (!data.make) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {makeErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.make, patterns.makeChars) || data.make === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Make
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Model<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="model"
                        className="form-control"
                        maxLength="200"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("model", e.target.value);
                        }}
                        value={data.model}
                      />
                      {Object.keys(modelErr).map((key) => {
                        if (!data.model) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {modelErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.model, patterns.areaChars) || data.model === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid model
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Serial Number</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="serial"
                        className="form-control"
                        disabled={screen}
                        maxLength="4"
                        onChange={(e) => {
                          handleInputChange("serial", e.target.value);
                        }}
                        value={data.serial}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Rating/Capacity<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="rating"
                        className="form-control"
                        maxLength="200"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("rating", e.target.value);
                        }}
                        value={data.rating}
                      />
                      {Object.keys(rcErr).map((key) => {
                        if (!data.rating) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {rcErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.rating, patterns.rncChars) || data.rating === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        UoM of Rating/Capacity
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="uom"
                        disabled={screen}
                        className="form-control"
                        maxLength="100"
                        onChange={(e) => {
                          handleInputChange("uom", e.target.value);
                        }}
                        value={data.uom}
                      />
                      {Object.keys(uomErr).map((key) => {
                        if (!data.uom) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {uomErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.unitofmeasurement, patterns.uomChars) ||
                        data.unitofmeasurement === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid UOM
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Manufacture</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="life"
                        disabled={screen}
                        className="form-control"
                        maxLength="4"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleInputChange("manufactureYear", value);
                          }
                        }}
                        value={data.manufactureYear}
                      />
                      {validateChars(data.manufactureYear, patterns.numChars) ||
                        data.manufactureYear === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Manufacture Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Installation</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="installationYear"
                        className="form-control"
                        maxLength="4"
                        disabled={screen}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleInputChange("installationYear", value);
                          }
                        }}
                        value={data.installationYear}
                      />
                      {validateChars(data.installationYear, patterns.numChars) ||
                        data.installationYear === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Installation Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Expected Life in Months</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="life"
                        className="form-control"
                        disabled={screen}
                        maxLength="4"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleInputChange("life", value);
                          }
                        }}
                        value={data.life}
                      />
                      {validateChars(data.life, patterns.numChars) || data.life === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Manufacture Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Criticality<span className="text-danger">*</span>
                      </label>
                      <select
                        name="criticality"
                        className="form-select"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("criticality", e.target.value);
                        }}
                        value={data.criticality}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    {Object.keys(uomErr).map((key) => {
                        if (!data.uom) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {uomErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                    <div className="mb-2 col-md-3">
                      <label>
                        Area<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="area"
                        className="form-control"
                        maxLength="200"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("area", e.target.value);
                        }}
                        value={data.area}
                      />
                      {Object.keys(areaErr).map((key) => {
                        if (!data.area) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {areaErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.area, patterns.areaChars) || data.area === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Area
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Location
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        disabled={screen}
                        name="location"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("location", e.target.value);
                        }}
                        value={data.location}
                      />
                      {Object.keys(locationErr).map((key) => {
                        if (!data.location) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {locationErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.location, patterns.areaChars) || data.location === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Location
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Building</label>
                      <input
                        type="text"
                        name="building"
                        className="form-control"
                        disabled={screen}
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("building", e.target.value);
                        }}
                        value={data.building}
                      />
                      {validateChars(data.building, patterns.idChars) || data.building === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Building
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Block/Tower</label>
                      <input
                        type="text"
                        name="block"
                        className="form-control"
                        disabled={screen}
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("block", e.target.value);
                        }}
                        value={data.block}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Floor</label>
                      <input
                        type="text"
                        name="floor"
                        className="form-control"
                        disabled={screen}
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("floor", e.target.value);
                        }}
                        value={data.floor}
                      />
                      {validateChars(data.floor, patterns.areaChars) || data.floor === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Floor
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Wing</label>
                      <input
                        type="text"
                        name="wing"
                        disabled={screen}
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("wing", e.target.value);
                        }}
                        value={data.wing}
                      />
                      {validateChars(data.wing, patterns.idChars) || data.wing === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Wing
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Department <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="dept"
                        disabled={screen}
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleInputChange("dept", e.target.value);
                        }}
                        value={data.dept}
                      />
                      {Object.keys(deptErr).map((key) => {
                        if (!data.dept) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {deptErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Network Accessibility</label>
                      <select
                        name="accessibility"
                        className="form-select"
                        disabled={screen}
                        onChange={(e) => {
                          handleInputChange("accessibility", e.target.value);
                        }}
                        value={data.accessibility}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Asset Image</label>
                      <input
                        type="file"
                        name="photo"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleInputChange("photo", e.target.value);
                        }}
                        value={data.photo}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Remarks</label>
                      <input
                        type="text"
                        name="remark"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleInputChange("remark", e.target.value);
                        }}
                        value={data.remark}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>URL</label>
                      <input
                        type="text"
                        name="url"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleInputChange("url", e.target.value);
                        }}
                        value={data.url}
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      {screen?'':
                      <button
                        type="submit"
                        onClick={() => updateAssetDetails()}
                        className="float-end saveButton"
                      >
                        Update
                      </button>}{" "}
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={() => navigate("/assetlist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetUpdate;
