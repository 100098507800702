import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";


function reportBasedOnUser(data) {
  return serviceBase.get(CONSTANTS.REPORT_BASED_ON_USER + `?type=${data[0]}&q=${data[5]}&start_date=${data[1]}&end_date=${data[2]}&page=${data[3]}&limit=${data[4]}&site=${data['id']}`);
}

// CUSTOM REPORT BUILDER API SERVICE

function getReportBuilderData() {
  return serviceBase.get(CONSTANTS.GET_COLLECTIONS);
}


function getReportBuilderQuery(data) {
  return serviceBase.post(CONSTANTS.GET_QUERY, data);
}


function getReportBuilderAttributes(data) {

  return serviceBase.get(CONSTANTS.GET_ATTRIBUTES + `?collection=${data}`);
}

function getReportBulderFieldValues(data) {
  return serviceBase.get(CONSTANTS.GET_FIELD_NAME + `?collection=${data?.collection}&field=${data?.field}`);
}


function getListOfReport(data) {
  return serviceBase.get(CONSTANTS.GET_REPORT + `?page=${data[0]}&limit=${data[1]}&search=${data[2]}&id=${data[3]}`);
}

function deleteReport(data) {
  return serviceBase.delete(CONSTANTS.DELETE_REPORT + `${data}`);
}

function saveReportBuilderQuery(data) {
  return serviceBase.post(CONSTANTS.SAVE_QUERY, data);
}

function updateReportBuilderQuery(data) {
  return serviceBase.patch(CONSTANTS.UPDATE_QUERY + data._id, data);
}

function getReportTemplate(data) {
  if (data?.page) {
    let query = '';
    if (data?.page) {
      query = query + `?page=${data.page}`;
    }
    if (data?.limit) {
      query = query + `&limit=${data.limit}`;
    }
    if (data?.search) {
      query = query + `&search=${data.search}`;
    }
    if (data?.id) {
      query = query + `&id=${data.id}`;
    }
    
    return serviceBase.get(CONSTANTS.GET_TEMPLATE_REPORT + query+`&site=${data.site}`);
  } else {
    return serviceBase.get(CONSTANTS.GET_TEMPLATE_REPORT+`?site=${data.site}`);
  }
}

// function getSingleReport(data){
//   let query = '';
//   query = query + `?start_date=${data.startDate}&end_date=${data.endDate}`
//   // console.log(CONSTANTS.GET_SINGLE_REPORT +`${data.firstId}/${data.secondId}`+query);
//   return serviceBase.get(CONSTANTS.GET_SINGLE_REPORT +`${data.firstId}/${data.secondId}`+query);
// }
function getSingleReport(data) {
  return serviceBase.post(CONSTANTS.GET_SINGLE_REPORT + `${data.firstId}`, data.body);
}

function getAssetsList(id,siteId){
  return serviceBase.get(CONSTANTS.GET_ASSETS_LIST + id +`?site=${siteId}`);
}

function getReportBuilderQueryList(data) {

  if (data?.page) {
    let query = '';
    if (data?.page) {
      query = query + `?page=${data.page}`;
    }
    if (data?.limit) {
      query = query + `&limit=${data.limit}`;
    }
    if (data?.search) {
      query = query + `&search=${data.search}`;
    }
    if (data?.id) {
      query = query + `&id=${data.id}`;
    }

    return serviceBase.get(CONSTANTS.GET_QUERY_LIST + query);
  } else {
    return serviceBase.get(CONSTANTS.GET_QUERY_LIST);
  }

}

function deleteReportBuilderQuery(data) {
  return serviceBase.delete(CONSTANTS.DELETE_QUERY + data);
}

function saveReport(data) {
  return serviceBase.post(CONSTANTS.SAVE_REPORT, data)

}

export const reportDetails = {
  reportBasedOnUser,
  getReportBuilderData,
  getReportBuilderQuery,
  getReportBuilderAttributes,
  getReportBulderFieldValues,
  getListOfReport,
  deleteReport,
  saveReportBuilderQuery,
  getReportBuilderQueryList,
  updateReportBuilderQuery,
  deleteReportBuilderQuery,
  saveReport,
  getReportTemplate,
  getAssetsList,
  getSingleReport,
};