// ReportForm.js

import React, { useState } from 'react';


const ReportTable = ({ headerData, bodyData, headerData2, verticalBodyData, verticalBodyData2, pageWidth }) => {


  return (
    <div>
      <table className="table" style={{ margin: "auto", width: pageWidth || 1200, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
        <thead style={{ width: '100%' }}>
          <tr>
            {headerData && headerData?.map((eachData,index) => (
              <td key={index} colSpan={eachData?.colSpan} rowSpan={eachData?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData?.textAlign, boxSizing: 'border-box', wordWrap: 'break-word', fontWeight: 'bold' }}>
                {eachData?.data.split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </td>
            ))}
          </tr>
          <tr>
            {headerData2 && headerData2?.map((eachData) => (
              <td colSpan={eachData?.colSpan} rowSpan={eachData?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData?.textAlign, padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word', fontWeight: 'bold', backgroundColor:eachData?.backgroundColor }}>
                {eachData?.data}
              </td>
            ))}
          </tr>
        </thead>
        <tbody style={{ width: '100%', marginTop: '60px' }}>
          {/* Horizontal body data */}
          <tr>
            {bodyData && bodyData?.map((eachData) => (
              <td
                colSpan={eachData?.colSpan}
                rowSpan={eachData?.rowSpan}
                style={{
                  border: '1px solid',
                  verticalAlign: eachData?.verticalAlign ? eachData?.verticalAlign : 'middle',
                  textAlign: eachData?.textAlign,
                  backgroundColor:eachData?.backgroundColor,
                  boxSizing: 'border-box',
                  whiteSpace: 'pre-wrap', // Add whiteSpace property
                  overflowWrap: 'break-word' // Add overflowWrap property
                }}
              >
                {eachData?.data}
              </td>
            ))}
          </tr>
          {/* {verticalData body data } */}
          {verticalBodyData && verticalBodyData?.map((eachData, index) => (
            <tr key={index}>
              {Object.keys(eachData)?.map((key, i) => (
                <td
                  key={i}
                  colSpan={eachData[key]?.style?.colSpan}
                  rowSpan={eachData[key]?.style?.rowSpan}
                  style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData[key]?.style?.textAlign, boxSizing: 'border-box', wordWrap: 'break-word' }}
                >
                  {eachData[key]?.value}
                </td>
              ))}
            </tr>
          ))}
          {verticalBodyData2 && verticalBodyData2?.bodyData?.map((eachData, index) => (
            <tr key={index}>
              {verticalBodyData2?.bodyData2 && verticalBodyData2?.bodyData2[index]!==undefined ?
              <td colSpan={verticalBodyData2?.bodyData2[index]?.colSpan} rowSpan={verticalBodyData2?.bodyData2[index]?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData?.textAlign }}>
                {verticalBodyData2?.bodyData2[index]?.data}
              </td>:''}
              <td colSpan={eachData?.colSpan} rowSpan={eachData?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData?.textAlign }}>
                {eachData.data}
              </td>
              {verticalBodyData2?.bodyData3 && verticalBodyData2?.bodyData3[index]!==undefined ?
              <td colSpan={verticalBodyData2?.bodyData3[index]?.colSpan} rowSpan={verticalBodyData2?.bodyData3[index]?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: eachData?.textAlign }}>
                {verticalBodyData2?.bodyData3[index]?.data}
              </td>:''}
              { verticalBodyData2?.name && verticalBodyData2?.name =='Insecticutor'?
              Object.keys(verticalBodyData2?.data)?.map((key, i) => (
                <React.Fragment key={i}>
                  {(index === i) && verticalBodyData2?.data[key]?.map((el, j) => (
                    <td key={j} colSpan={1} rowSpan={el?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: el?.textAlign, padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word' }}>
                      {el}
                    </td>
                  ))}
                </React.Fragment>
              )):Object.keys(verticalBodyData2?.data)?.map((key, i) => (
                <React.Fragment key={i}>
                  {(index === i + 1) && verticalBodyData2?.data[key]?.map((el, j) => (
                    <td key={j} colSpan={2} rowSpan={el?.rowSpan} style={{ border: '1px solid', verticalAlign: 'middle', textAlign: el?.textAlign, padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word' }}>
                      {el}
                    </td>
                  ))}
                </React.Fragment>
              ))}
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
