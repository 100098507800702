//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import IMAGENAME from "../../../medias/media.index";
import { useSelector, useDispatch } from "react-redux";
import './reportsStyle.css'
import S3BucketImage from "./s3BucketImages";
import Loader from "../../../components/loader/loader";


// function of Ticket Report Preview page to download report
const TicketReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.site.isLoading);

  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState([]);
  const [key, setKey] = useState('');
  const [selectedItem, setSelectedItem] = useState("Asset");

  const ticketReportDataArray = useSelector((state) => state?.ticket?.ticketReportData?.result);

  /////////////dispatch function to get ticket report data////////////
  const getInsReport = (ticketId) => {
    let idArray = ticketId.split(",")
    dispatch({
      type: "TICKET_REPORT",
      payload: { ticket_mid: idArray },
    });

  };
  //useEffects
  useEffect(() => {
    if (ticketReportDataArray !== null) {
      // console.log(ticketReportData);
    }
  }, [ticketReportDataArray]);

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getInsReport(id);
    }
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0])
      setSelectedItem(location.state?.[1])
    }
  }, [])

  useEffect(() => {
    // { console.log('imageSrc', imageSrc) }
  }, [imageSrc])

  const convertToDMS = (coordinate, isLatitude) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude
      ? coordinate >= 0 ? 'N' : 'S'
      : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }

  const capitalisedfunction = (status) => {
    if (!status) return "NA"; // Handle null, undefined, or empty status
    return status
      .split("_") // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words with spaces
  };

  ///////////////////////////////HTML//////////////////////////////////
  return (
    <>
      {isLoading && <Loader />}
      {ticketReportDataArray !== undefined && ticketReportDataArray?.length > 0 && ticketReportDataArray?.map((ticketReportData) => {
        return <div >
          {ticketReportData !== undefined &&
            <table className="table" style={{ margin: "auto", width: 1000, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
              <thead style={{ width: '100%' }}>
                <tr>
                  <th scope="col" colSpan="10" style={{ border: '1px solid', width: '200px', textAlign: 'center', verticalAlign: 'middle' }}>
                    <img
                      src={IMAGENAME.logo}
                      alt="ZongoVita logo"
                      style={{
                        width: "150px",
                        alignItems: 'center'
                        // padding: '0.2rem',
                        // margin: 'auto',
                      }}
                    /></th>
                  <th colSpan="40" style={{ border: '1px solid', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'normal' }}>
                    {ticketReportData?.customer ? (
                      <>
                        {`${ticketReportData.customer.legal_name} - ${ticketReportData.customer.city}, ${ticketReportData.customer.state}`}
                        <br />
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {`Report : ${ticketReportData.ticket?.name}`}
                        </span>
                      </>
                    ) : (
                      'No Data Available' // Fallback text if `ticketReportData` or its nested properties are null/undefined
                    )}
                  </th>
                  <th colSpan="10" style={{ border: '1px solid', width: '200px', textAlign: 'center', verticalAlign: 'middle' }}>
                    <S3BucketImage
                      imageUrl={ticketReportData?.customer?.cust_logo}
                      widthSize={'auto'}
                    />
                  </th>
                </tr>
                <tr style={{ verticalAlign: 'middle' }}>
                  <th colSpan="15" style={{ border: '1px solid' }}>
                    {ticketReportData?.ticket?.id !== null ? (
                      <div style={{ width: "100%", wordBreak: "break-word" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}> Ticket Id -  <span style={{ fontWeight: 'lighter' }}>{`${ticketReportData?.ticket?.id}`}</span>  </p>
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        {/* <p>Start Date & Time - NA </p> */}
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}> Ticket Id - <span style={{ fontWeight: 'lighter' }}> NA</span> </p>
                      </div>
                    )}
                  </th>
                  <th colSpan="15" style={{ border: '1px solid' }}>
                    {ticketReportData?.ticket?.service?.criticality !== null ? (
                      <div style={{ width: "100%" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}>Criticality - <span style={{ fontWeight: 'lighter' }}>  {ticketReportData?.ticket?.service?.criticality}</span> </p>
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}>Criticality - <span style={{ fontWeight: 'lighter' }}>NA</span></p>
                      </div>
                    )}
                  </th>
                  <th colSpan="10" style={{ border: '1px solid' }}>
                    {ticketReportData?.ticket?.status !== null ? (
                      <div style={{ width: "100%" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}>Status - <span style={{ fontWeight: 'lighter' }}>{ticketReportData?.ticket?.status == 'approved' ? "Closed" : capitalisedfunction(ticketReportData?.ticket?.status)}</span>  </p>
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}>Status -<span style={{ fontWeight: 'lighter' }}> NA </span></p>
                      </div>
                    )}
                  </th>
                  <th colSpan="20" style={{ border: '1px solid' }}>
                    {ticketReportData?.ticket?.updatedAt !== null ? (
                      <div style={{ width: "100%" }}>
                        <p style={{ verticalAlign: 'middle', margin: '0px' }}>Updated at - <span style={{ fontWeight: 'lighter' }}>{`
                  ${ticketReportData?.ticket?.updatedAt !== null && ticketReportData?.ticket?.updatedAt !== '' && ticketReportData?.ticket?.updatedAt !== undefined ? moment(
                          ticketReportData?.ticket?.updatedAt
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                        </span> </p>
                      </div>
                    ) : (<div style={{ width: "100%" }}>
                      <p style={{ verticalAlign: 'middle', margin: '0px' }}>Updated at - <span style={{ fontWeight: 'lighter' }}> NA</span> </p>
                    </div>
                    )}
                  </th>
                </tr>
                <tr>
                  <th colSpan={ticketReportData?.ticket?.reopen?.length > 0 ? "30" : "60"} style={{ border: '1px solid', verticalAlign: 'middle', }}>TAT (hh:mm:ss) -
                    <span style={{ fontWeight: 'lighter', verticalAlign: 'middle', }}> {ticketReportData?.ticket?.ticket_duration?moment
                      .utc(
                        moment
                          .duration(
                            ticketReportData?.ticket?.ticket_duration,
                            "seconds"
                          )
                          .asSeconds() * 1000
                      )
                      .format("HH:mm:ss"):''
                      // moment().seconds(ticketReportData.inspection.inspection_duration).format("hh:mm:ss")
                      }</span></th>

                  {ticketReportData?.ticket?.reopen?.length > 0 ?
                    <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Reopen Count - <span style={{ fontWeight: 'lighter', verticalAlign: 'middle' }}>
                      {`${ticketReportData?.ticket?.reopen?.length > 0 ? (ticketReportData?.ticket?.reopen?.length < 10 ? `0${ticketReportData?.ticket?.reopen?.length}` : ticketReportData?.ticket?.reopen?.length) : '00'}`}
                    </span>
                    </th> : ''}
                </tr>
                <tr>
                  <th colSpan="60" style={{
                    fontWeight: "bold",
                    backgroundColor: "#0862B4",
                    color: "white",
                    textAlign: "center",
                    borderBottom: 'none',
                    padding: "7px",
                    borderTop: '1px black',
                    margin: '0px',
                    verticalAlign: 'middle',
                    alignItems: 'center'
                  }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Details</th>
                </tr>
                <tr>
                  <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} ID - <span style={{ fontWeight: 'lighter' }}> {`${ticketReportData?.parent?.parent_id || "NA"}`}</span></th>
                  <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Name -
                    <span style={{ fontWeight: 'lighter', verticalAlign: 'middle', }}> {`${ticketReportData?.parent?.parent_name || "NA"}`}</span>
                  </th>
                  <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Department -
                    <span style={{ fontWeight: 'lighter', verticalAlign: 'middle', }}>  {`${ticketReportData?.parent?.department || "NA"}`}</span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    <span style={{ fontWeight: 'bold' }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Geo Location - Lat : </span>
                    <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.parent?.parent_geo_location?.coordinates?.length > 0
                        ? convertToDMS(ticketReportData?.parent?.parent_geo_location?.coordinates[1], true)
                        : 'NA'}
                    </span>
                    <span style={{ fontWeight: 'bold' }}> & Long : </span>
                    <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.parent?.parent_geo_location?.coordinates?.length > 0
                        ? convertToDMS(ticketReportData?.parent?.parent_geo_location?.coordinates[0], false)
                        : 'NA'}
                    </span>
                  </th>
                  <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    <span style={{ fontWeight: 'bold' }}>{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Location - </span>
                    <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.parent?.location || 'NA'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="60" style={{
                    fontWeight: "bold",
                    backgroundColor: "#0862B4",
                    color: "white",
                    textAlign: "center",
                    borderBottom: 'none',
                    padding: "7px",
                    borderTop: '1px black',
                    verticalAlign: 'middle',
                    margin: '0px',
                    alignItems: 'center'
                  }}>Creation Details</th>
                </tr>
                <tr>
                  <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    Created Date & Time - <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.ticket?.createdAt !== null &&
                        ticketReportData?.ticket?.createdAt !== '' &&
                        ticketReportData?.ticket?.createdAt !== undefined
                        ? moment(ticketReportData?.ticket?.createdAt).format("MMMM Do YYYY | hh:mm:ss A")
                        : 'NA'}
                    </span>
                  </th>
                  <th colSpan="15" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    Created By - <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.ticket?.created_by || 'NA'}
                    </span>
                  </th>
                  <th colSpan="15" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    Creation Mode - <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.ticket?.entry_type || 'NA'}
                    </span>
                  </th>
                </tr>

                <tr>
                  <th colSpan="10" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    Issue Details
                  </th>
                  <th colSpan="50" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    <span style={{ fontWeight: 'lighter' }}>
                      {ticketReportData?.ticket?.service?.problem_description || 'NA'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="10" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    Condition
                  </th>
                  <th colSpan="50" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                    <span style={{ fontWeight: 'lighter' }}>
                      {capitalisedfunction(ticketReportData?.ticket?.service?.status)}
                    </span>
                  </th>
                </tr>
                {ticketReportData?.ticket?.attachment?.length > 0 ?
                  <><tr>
                    <th colSpan="60" style={{
                      fontWeight: "bold",
                      // backgroundColor: "#0862B4",
                      // color: "white",
                      textAlign: "center",
                      borderBottom: 'none',
                      padding: "7px",
                      borderTop: '1px black',
                      margin: '0px',
                      alignItems: 'center'
                    }}>Attached Images - During Ticket Creation</th>
                  </tr>
                    <tr>
                      {ticketReportData?.ticket?.attachment?.map((el, i) => {
                        const attachmentLength = ticketReportData?.ticket?.attachment?.length;
                        return (
                          <th
                            key={i}
                            colSpan={60 / attachmentLength}
                            scope="col"
                            style={{
                              border: '1px solid',
                              width: '200px',
                              textAlign: 'center',
                              verticalAlign: 'middle'
                            }}
                          >
                            <S3BucketImage
                              imageUrl={el}
                              widthSize={'auto'}
                            />
                          </th>
                        );
                      })}

                    </tr>
                  </> : <></>
                }
                {ticketReportData?.ticket?.assignment?.assigned_by &&
                  <>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Assigned to - <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.assignment?.assignee?.length > 0
                            ? ticketReportData?.ticket?.assignment?.assignee
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Assigned by - <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.assignment?.assigned_by || "NA"}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Assigned Geo Location -  Lat : <span style={{ fontWeight: 'lighter' }}>{ticketReportData?.ticket?.assignment?.geo_loc?.coordinates?.length > 0
                          ? convertToDMS(ticketReportData?.ticket?.assignment?.geo_loc?.coordinates[1], true)
                          : "NA"} </span>  &
                        Long : <span style={{ fontWeight: 'lighter' }}> {ticketReportData?.ticket?.assignment?.geo_loc?.coordinates?.length > 0
                          ? convertToDMS(ticketReportData?.ticket?.assignment?.geo_loc?.coordinates[0], false)
                          : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Assigned Date & Time -  <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.assignment?.assigned_date !== null &&
                            ticketReportData?.ticket?.assignment?.assigned_date !== '' &&
                            ticketReportData?.ticket?.assignment?.assigned_date !== undefined
                            ? moment(ticketReportData?.ticket?.assignment?.assigned_date).format("MMMM Do YYYY | hh:mm:ss A")
                            : "NA"}
                        </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.performance?.performed_by &&
                  <>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                      }}>Performance Details</th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Performed Date & Time - <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.performance?.performed_date !== null &&
                            ticketReportData?.ticket?.performance?.performed_date !== '' &&
                            ticketReportData?.ticket?.performance?.performed_date !== undefined
                            ? moment(ticketReportData?.ticket?.performance?.performed_date).format("MMMM Do YYYY | hh:mm:ss A")
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Performed By - <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.performance?.performed_by || "NA"}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="40" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Performer Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.performance?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter', verticalAlign: 'middle', }}>
                          {ticketReportData?.ticket?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.performance?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Duration (hh:mm:ss) - <span style={{ fontWeight: 'lighter' }}>{`${ticketReportData?.ticket?.performance?.performance_duration !== null && ticketReportData?.ticket?.performance?.performance_duration !== '' && ticketReportData?.ticket?.performance?.performance_duration !== undefined ? moment
                        .utc(
                          moment
                            .duration(
                              ticketReportData?.ticket?.performance?.performance_duration,
                              "seconds"
                            )
                            .asSeconds() * 1000
                        )
                        .format("HH:mm:ss") : "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" style={{ border: '1px solid', verticalAlign: 'middle', }}>Root Cause Type - <span style={{ fontWeight: 'lighter' }}>{`${ticketReportData?.ticket?.performance?.root_cause?.type || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {
                      ticketReportData?.ticket?.performance?.root_cause?.type == 'direct' ?
                        <tr>
                          <th colSpan="60" style={{ border: '1px solid', verticalAlign: 'middle', }}>Details -<span style={{ fontWeight: 'lighter' }}>
                            {`${ticketReportData?.ticket?.performance?.root_cause?.direct !== '' ? ticketReportData?.ticket?.performance?.root_cause?.direct : '-'}`}
                          </span>
                          </th>
                        </tr> :
                        <tr>
                          {ticketReportData?.ticket?.performance?.root_cause?.tool?.length > 0 &&
                            ticketReportData?.ticket?.performance?.root_cause?.tool?.map((el, i) => {
                              return (
                                <th
                                  colSpan={60 / ticketReportData?.ticket?.performance?.root_cause?.tool?.length}
                                  key={i}
                                  style={{ border: '1px solid', alignContent: 'start' }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>{`Why ${i + 1}`}</span>
                                  <br />
                                  <br />
                                  <span style={{ fontWeight: 'bold' }}>Reason: </span>
                                  <span style={{ fontWeight: 'lighter' }}>{el}</span>
                                </th>
                              );
                            })}
                        </tr>
                    }
                    <tr>
                      <th colSpan="10" style={{ border: '1px solid', verticalAlign: 'middle', }}>Solution
                      </th>
                      <th colSpan="50" style={{ border: '1px solid', verticalAlign: 'middle', }}><span style={{ fontWeight: 'lighter' }}>{` 
                  ${ticketReportData?.ticket?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {ticketReportData?.ticket?.performance?.attachment?.length > 0 ?
                      <><tr>
                        <th colSpan="60" style={{
                          fontWeight: "bold",
                          // backgroundColor: "#0862B4",
                          // color: "white",
                          textAlign: "center",
                          borderBottom: 'none',
                          padding: "7px",
                          borderTop: '1px black',
                          margin: '0px',
                          alignItems: 'center'
                        }}>Attached Images - During Ticket Performance</th>
                      </tr>
                        <tr>
                          {ticketReportData?.ticket?.performance?.attachment?.map((el, i) => {
                            const attachmentLength = ticketReportData?.ticket?.performance?.attachment?.length;
                            return (
                              <th
                                key={i}
                                colSpan={60 / attachmentLength}
                                scope="col"
                                style={{
                                  border: '1px solid',
                                  width: '200px',
                                  textAlign: 'center',
                                  verticalAlign: 'middle'
                                }}
                              >
                                <S3BucketImage
                                  imageUrl={el}
                                  widthSize={'auto'}
                                />
                              </th>
                            );
                          })}

                        </tr>
                      </> : ''
                    }
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>{`${ticketReportData?.ticket?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {ticketReportData?.ticket?.performance?.signature ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.performance?.signature}
                            // imageUrl={el}
                            widthSize={'100px'}
                          /> : "-NA-"}
                      </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.approval?.approved_by &&
                  <>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>Approval Details</th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Approved On - <span style={{ fontWeight: 'lighter' }}>{`
                  ${ticketReportData?.ticket?.approval?.approved_date !== null && ticketReportData?.ticket?.approval?.approved_date !== '' && ticketReportData?.ticket?.approval?.approved_date !== undefined ? moment(
                        ticketReportData?.ticket?.approval?.approved_date
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="15" style={{ border: '1px solid', verticalAlign: 'middle', }}>Approved By - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.approval?.approved_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="15" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {ticketReportData?.ticket?.approval?.signature ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.approval?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Approver Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.approval?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.approval?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.approval?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.approval?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.approval?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.reopen?.length > 0 && <tr>
                  <th colSpan="60" style={{
                    fontWeight: "bold",
                    backgroundColor: "#0862B4",
                    color: "white",
                    textAlign: "center",
                    borderBottom: 'none',
                    padding: "7px",
                    borderTop: '1px black',
                    margin: '0px',
                    alignItems: 'center'
                  }}>Ticket History</th>
                </tr>
                }
                {ticketReportData?.ticket?.reopen?.length > 0 && ticketReportData?.ticket?.reopen?.map((el, i) => {
                  return <>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        border: '1px solid',
                        backgroundColor: "#548DD4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>{`Performance Details for Reopen - ${el?.createdAt !== null && el?.createdAt !== '' && el?.createdAt !== undefined ? moment(
                        el?.createdAt
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}</th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Performed Date & Time -  <span style={{ fontWeight: 'lighter' }}>{`${el?.performance?.performed_date !== null && el?.performance?.performed_date !== '' && el?.performance?.performed_date !== undefined ? moment(
                        el?.performance?.performed_date
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}</span></th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Performed By -  <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.performance?.performed_by || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="40" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Performer Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {el?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.performance?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter' }}>
                          {el?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.performance?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Duration (hh:mm:ss) - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.performance?.performance_duration !== null && el?.performance?.performance_duration !== '' && el?.performance?.performance_duration !== undefined ? moment
                          .utc(
                            moment
                              .duration(
                                el?.performance?.performance_duration,
                                "seconds"
                              )
                              .asSeconds() * 1000
                          )
                          .format("HH:mm:ss") : "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" style={{ border: '1px solid', verticalAlign: 'middle', }}>Root Cause Type - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.performance?.root_cause?.type || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {
                      el?.performance?.root_cause?.type == 'direct' ?
                        <tr>
                          <th colSpan="60" style={{ border: '1px solid', verticalAlign: 'middle', }}>Details - <span style={{ fontWeight: 'lighter' }}>
                            {`${el?.performance?.root_cause?.direct !== '' ? el?.performance?.root_cause?.direct : '-'}`}
                          </span>
                          </th>
                        </tr> :
                        <tr>
                          {/* {el?.performance?.root_cause?.tool?.length > 0 &&
                            el?.performance?.root_cause?.tool?.map((ele, i) => {
                              return (
                                <th colSpan={60 / el?.performance?.root_cause?.tool?.length} key={i} style={{ border: '1px solid' }}>
                                  {`Why ${i + 1}`} <br />
                                  Reason: <span style={{ fontWeight: 'lighter' }}>{ele}</span>
                                </th>
                              );
                            })} */}
                          {el?.performance?.root_cause?.tool?.length > 0 &&
                            el?.performance?.root_cause?.tool?.map((ele, i) => {
                              return (
                                <th
                                  colSpan={60 / el?.performance?.root_cause?.tool?.length}
                                  key={i}
                                  style={{ border: '1px solid', alignContent: 'start' }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>{`Why ${i + 1}`}</span>
                                  <br />
                                  <br />
                                  <span style={{ fontWeight: 'bold' }}>Reason: </span>
                                  <span style={{ fontWeight: 'lighter' }}>{ele}</span>
                                </th>
                              );
                            })}
                        </tr>
                    }
                    <tr>
                      <th colSpan="10" style={{ border: '1px solid', verticalAlign: 'middle', }}>Solution
                      </th>
                      <th colSpan="50" style={{ border: '1px solid', verticalAlign: 'middle', }}><span style={{ fontWeight: 'lighter' }}>{` 
                  ${el?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {el?.performance?.attachment?.length > 0 ?
                      <><tr>
                        <th colSpan="60" style={{
                          fontWeight: "bold",
                          // backgroundColor: "#0862B4",
                          // color: "white",
                          textAlign: "center",
                          borderBottom: 'none',
                          padding: "7px",
                          borderTop: '1px black',
                          margin: '0px',
                          alignItems: 'center'
                        }}>Attached Images - During Ticket Performance</th>
                      </tr>
                        <tr>
                          {el?.performance?.attachment?.map((ele, i) => {
                            const attachmentLength = el?.performance?.attachment?.length;
                            return (
                              <th
                                key={i}
                                colSpan={60 / attachmentLength}
                                scope="col"
                                style={{
                                  border: '1px solid',
                                  width: '200px',
                                  textAlign: 'center',
                                  verticalAlign: 'middle'
                                }}
                              >
                                <S3BucketImage
                                  imageUrl={ele}
                                  widthSize={'auto'}
                                />
                              </th>
                            );
                          })}

                        </tr>
                      </> : ''
                    }
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {el?.performance?.signature?
                          <S3BucketImage
                            imageUrl={el?.performance?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        border: '1px solid',
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>Reopen Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Reopened On - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.reopened_date !== null && el?.reopened_date !== '' && el?.reopened_date !== undefined ? moment(
                          el?.reopened_date
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Reopened By - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.reopened_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {el?.signature ?
                          <S3BucketImage
                            imageUrl={el?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Reopened Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter' }}>
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>
                        {`${el?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                })}
                {ticketReportData?.ticket?.status === "rejected" &&
                  <>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        border: '1px solid',
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>Rejection Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Rejected On - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.rejection?.rejected_date !== null && ticketReportData?.ticket?.rejection?.rejected_date !== '' && ticketReportData?.ticket?.rejection?.rejected_date !== undefined ? moment(
                          ticketReportData?.ticket?.rejection?.rejected_date
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Rejected By - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.rejection?.rejected_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.rejection?.signature !== null && ticketReportData?.ticket?.rejection?.signature !== '' && ticketReportData?.ticket?.rejection?.signature !== undefined ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Rejecter Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.rejection?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.rejection?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.rejection?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.rejection?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.rejection?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                }
                {ticketReportData?.ticket?.status === "cancelled" &&
                  <>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        border: '1px solid',
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>Cancellation Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Cancelled On - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.cancellation?.cancelled_date !== null && ticketReportData?.ticket?.cancellation?.cancelled_date !== '' && ticketReportData?.ticket?.cancellation?.cancelled_date !== undefined ? moment(
                          ticketReportData?.ticket?.cancellation?.cancelled_date
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Cancelled By - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.cancellation?.cancelled_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" style={{ border: '1px solid', verticalAlign: 'middle', }}>Signature - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.cancellation?.signature !== null && ticketReportData?.ticket?.cancellation?.signature !== '' && ticketReportData?.ticket?.cancellation?.signature !== undefined ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.cancellation?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>
                        Canceller Geo Location - Lat : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span style={{ fontWeight: 'lighter' }}>
                          {ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" style={{ border: '1px solid', verticalAlign: 'middle', }}>Comments - <span style={{ fontWeight: 'lighter' }}>
                        {`${ticketReportData?.ticket?.cancellation?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                }
              </thead>
            </table>
          }
          <br />
          <br />
          <br />
          <br />
        </div >
      })}
    </>
  );
};

export default TicketReport;
