import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'


function TMR({ responseData, filterData, chklname, docNumber, frequency, depname, malur, data4, data5, data6, data7, headerName }) {

    const [resData, setResData] = useState([])

    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }
    const data3 = {
        data: `${chklname}`,
        colSpan: '7'
    }

    const groupedData = {};

    responseData?.checklist?.forEach(item => {
        let date = new Date(item.ins_sch_date).toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
        if (!groupedData[date]) {
            groupedData[date] = [];
        }
        groupedData[date].push(item);
    });

    // Convert the grouped data object into an array of objects
    const groupedDataArray = Object.keys(groupedData).map(date => ({
        date,
        items: groupedData[date]
    }));

    const transformData = (groupedDataArray) => {
        return groupedDataArray.map(group => {
            let transformedObject = {};

            // Set the date once for the entire group
            transformedObject['date'] = {
                value: moment(group.date).local().format("DD/MM/YY"),
                style: { textAlign: 'center' },
            };

            // Iterate over items and add time and other properties
            group.items.forEach((item, index) => {
                const baseIndex = index * 2;

                transformedObject[`data${baseIndex}`] = {
                    value: item?.ins_sch_date && item?.ins_sch_date !== undefined ? moment(item?.ins_sch_date).local().format("hh:mm A") : '-',  // received email on 31st jan to maintain this date format.
                    style: { textAlign: 'center' }, // Define inline style
                };
                transformedObject[`data${baseIndex + 1}`] = {
                    value: item?.checkpoints[2]?.answer? item?.checkpoints[2]?.answer : '-',
                    style: { textAlign: 'center' }, // Define inline style
                };
            });
            if (Object.keys(transformedObject).length === 7) {
                return transformedObject;
            } else {
                let num = 7 - Object.keys(transformedObject).length;
                let indexnum = Object.keys(transformedObject).length;
                for (let l = 0; l < num; l++) {
                    transformedObject[`data${indexnum + 1}`] = {
                        value: '-',
                        style: { textAlign: 'center' }, // Define inline style
                    };
                    indexnum++;
                }
                return transformedObject;
            }

        });
    };

    const tableResData1 = transformData(groupedDataArray);

    // const tableResData1 = []

    const tableResData = resData?.checklist?.map((item, index) => ({
        data0: {
            value: item?.start_date && item?.start_date !== undefined ? moment(item?.start_date).local().format("DD/MM/YY") : '-',  // received email on 31st jan to maintain this date format.
            style: { textAlign: 'center' }, // Define inline style here
        },
        data1: {
            value: item?.start_date && item?.start_date !== undefined ? moment(item?.start_date).local().format("hh:mm A") : '-',
            style: { textAlign: 'center' },
        },
        data2: {
            value: item?.checkpoints[2]?.answer !== '' ? item?.checkpoints[2]?.answer : '-',
            style: { textAlign: 'center' }, // Define inline style here
        },
        data3: {
            value: item?.performed_by?.length > 0 ? item?.performed_by : '-',
            style: { textAlign: 'center' }, // Define inline style here
        },
        data4: {
            value: item?.ins_approval_id?.length > 0 ? item?.ins_approval_id : '-',
            style: { textAlign: 'center' },
        },

    }));



    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
                malur={malur}
            />
            <ReportTable
                headerData={data4}
            />
            <ReportTable
                headerData={data5}
            />
            <ReportTable
                headerData={data7}
            />
            {headerName !== 'Twin' ?
                <ReportTable
                    headerData2={data6}
                    verticalBodyData={tableResData}
                /> : <ReportTable
                    headerData2={data6}
                    verticalBodyData={tableResData1}
                />}

        </div>
    )
}

export default TMR