//MAGNEHELIC GAUGES DIFFERENTIAL PRESSURE MONITORING LOG SHEET FOR AIR HANDLING UNIT
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
// import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function AhuLog({ responseData, filterData, data }) {
    const [resData, setResData] = useState([])

    // header details
    const data1 = {
        data: "QUALITY ASSURANCE",
        colSpan: '6'
    }
    const data2 = {
        data: "MAINT/017:F2-08"
    }
    const data3 = {
        data: `MAGNEHELIC GAUGES DIFFERENTIAL PRESSURE MONITORING LOG SHEET FOR AIR HANDLING UNIT`,
        colSpan: '7'
    }
    const data4 = [
        { data: `MONTH/YEAR :  ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`, colSpan: '6' },
        { data: 'LIMIT: 5.0 mm of wc to 20.0 mm of wc', colSpan: '6' },
    ]
    const data5 = [
        { data: `LOCATION: ${responseData?.parent !== undefined ? responseData?.parent?.location : '-'}`, colSpan: '6' },
        { data: `EQUIPMENT ID.: ${responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-'}`, colSpan: '6' },
    ]

    // Footer details
    const dataRow1 = [
        {
            data: 'Alert limit: 18 mm of WC',
            colSpan: '6'
        },
        {
            data: 'Action limit: 19 mm of WC',
            colSpan: '6'
        }
    ]

    // Report Body Data
    const tableHeaders = [
        { data: 'DATE', textAlign: 'center', rowSpan: '2' },
        { data: 'TIME', textAlign: 'center', colSpan: '1' },
        { data: 'READINGS (mm of wc)', textAlign: 'center', rowSpan: '2' },
        { data: 'CHECKED BY', textAlign: 'center', rowSpan: '2' },
        { data: 'TIME', textAlign: 'center', rowSpan: '2' },
        { data: 'READINGS (mm of wc)', textAlign: 'center', rowSpan: '2' },
        { data: 'CHECKED BY', textAlign: 'center', rowSpan: '2' },
        { data: 'TIME', textAlign: 'center', rowSpan: '2' },
        { data: 'READINGS (mm of wc)', textAlign: 'center', rowSpan: '2' },
        { data: 'CHECKED BY', textAlign: 'center', rowSpan: '2' },
    ]

    const groupedData = {};

    responseData?.checklist?.forEach(item => {
        const date = new Date(item.ins_sch_date).toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
        if (!groupedData[date]) {
            groupedData[date] = [];
        }
        groupedData[date].push(item);
    });

    // Convert the grouped data object into an array of objects
    const groupedDataArray = Object.keys(groupedData).map(date => ({
        date,
        items: groupedData[date]
    }));

    const transformData = (groupedDataArray) => {
        return groupedDataArray.map(group => {
            let transformedObject = {};

            // Set the date once for the entire group
            transformedObject['date'] = {
                value: moment(group.date).local().format("DD/MM/YY"),
                style: { textAlign: 'center' },
            };

            // Iterate over items and add time and other properties
            group.items.forEach((item, index) => {
                const baseIndex = index * 3;

                transformedObject[`data${baseIndex}`] = {
                    value: moment(item?.ins_sch_date !== undefined ? item?.ins_sch_date : item?.start_date).local().format("hh:mm A"),  // received email on 31st jan to maintain this date format.
                    style: { textAlign: 'center' }, // Define inline style
                };
                transformedObject[`data${baseIndex + 1}`] = {
                    value: item?.checkpoints[0]?.answer? item?.checkpoints[0]?.answer : '-',
                    style: { textAlign: 'center' }, // Define inline style
                };
                transformedObject[`data${baseIndex + 2}`] = {
                    value: item?.performed_by?.length > 0 ? item?.performed_by : '-',
                    style: { textAlign: 'center' }, // Define inline style
                };
            });
            if (Object.keys(transformedObject).length === 10) {
                return transformedObject;
            } else {
                let num = 10 - Object.keys(transformedObject).length;
                let indexnum = Object.keys(transformedObject).length;
                for (let l = 0; l < num; l++) {
                    transformedObject[`data${indexnum + 1}`] = {
                        value: '-',
                        style: { textAlign: 'center' }, // Define inline style
                    };
                    indexnum++;
                }
                return transformedObject;
            }
        });
    };

    const tableResData1 = transformData(groupedDataArray);

    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}

            />
            <ReportTable
                headerData2={data4}
            />
            <ReportTable
                headerData2={data5}
            />
            <ReportTable
                headerData={tableHeaders}
            />
            <ReportTable
                verticalBodyData={tableResData1}
            />
            <ReportTable
                headerData2={dataRow1}
            />
        </div>
    )
}

export default AhuLog
