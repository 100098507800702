import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { INSPECTION_ADD } from "../../../redux/actions/inspectionAction";
import "../inspection/inspectStyle.css";
import { INSPECTION_UPDATE } from "../../../redux/actions/inspectionAction";
import S3BucketImage from "../../report/pdfPreview/s3BucketImages";
import Approvals from "./approvals";
import CheckPoints from "./checkPoints";
import PdfDocView from "./pdfDocView";

const PlannedActivity = ({
  type,
  formdata,
  operation,
  id,
  submitData_upload,
  maintGetSingle,
  submitData_update,
  isSubmitted,
  sopData,
}) => {
  // Importing the useSearchParams hook from react-router-dom to work with query parameters in the URL
  const [queryParams] = useSearchParams();

  // Importing the useDispatch hook from react-redux to dispatch actions to the Redux store
  const dispatch = useDispatch();

  // Importing the useNavigate hook from react-router-dom to programmatically navigate between routes
  const navigate = useNavigate();

  // Using the useSelector hook from react-redux to access the state from the Redux store
  const isLoading = useSelector((state) => state.inspection.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const accessOnDemand = useSelector(
    (state) => state.access.onDemandInspection
  );
  const inspGetSingle = useSelector((state) => state.inspection.inspGetSingle);
  const role = useSelector((state) => state.auth.role);

  // define useState :
  const [showhide, setShowhide] = useState(""); // to handle the view of priority boolean
  const [hrs, setHrs] = useState([]); // to set the hour for repeat hours frequency
  const [daysOfWeek, setDaysOfWeek] = useState([]); // to set the days of week for repeat days frequency
  const [key, setKey] = useState(""); // to manage the type of the inspection - asset, device or facility

  const today = new Date().toISOString().slice(0, 10); // Getting the current date in ISO format (YYYY-MM-DD) and slicing it to keep only the date part (YYYY-MM-DD)

  // console.log('imageName', imageName);
  // console.log('imageArray', imageArray);

  //Array of Hours - its used for repeat hourly frequency check box
  const numOfHours = Array.from({ length: 48 }, (_, i) => {
    const hours = String(Math.floor(i / 2)).padStart(2, '0');
    const minutes = i % 2 === 0 ? '00' : '30';
    return `${hours}:${minutes}`;
  });

  //Array of days - its used for repeat days frequency check box
  const numOfDays = [
    { key: 0, value: "Sun" },
    { key: 1, value: "Mon" },
    { key: 2, value: "Tue" },
    { key: 3, value: "Wed" },
    { key: 4, value: "Thu" },
    { key: 5, value: "Fri" },
    { key: 6, value: "Sat" },
  ];

  // Initializing the state with an object that contains various frequency-related properties, all set to empty strings or empty arrays
  const [calendarFreqncy, setCalendarFreqncy] = useState({
    type: "", // Type of calendar frequency (e.g., daily, hourly)
    type_value: "", // Specific value for the frequency type (e.g. 1, 2 etc..)
    mits_of_hrs: "", // Minutes or hours for the frequency
    hrs_of_day: [], // Array to hold specific hours of the day
    days_of_week: [], // Array to hold specific days of the week
    end_type: "", // Type of end condition for the frequency (e.g., never, on a specific date)
    occur_value: "", // Value for occurrences if end type is based on occurrences
    date_value: "", // Specific date value if end type is based on a date
  });

  // Initializing the state with an object that contains various schedule-related properties, all set to empty strings or empty arrays
  const [schedule, setSchedule] = useState({
    parent_mid: id,
    inspection_name: "",
    type: "",
    priority: "",
    based_on: "",
    managed_by: "",
    assignee_type: "",
    assignee: [],
    inspection_duration: "",
    shut_down_duration: "",
    man_power_required: "",
    man_hour_required: "",
    sla: "",
    sla_interval: "",
    frequency: calendarFreqncy,
    checkpoints: [],
    maintenance_activities: [],
  });

  // state to handle SOP assign and view.
  const [viewPdf, setViewPdf] = useState("");
  const [showDocModel, setShowDocModel] = useState(false);
  const [sopSelectedActivity, setsopSelectedActivity] = useState("");

  //Radio button function to handle priority
  const handleShow = (e) => {
    const getshow = e.target.value;
    // console.log(getshow);
    setShowhide(getshow);
    setSchedule({ ...schedule, priority: e.target.value });
    setErrors((prevErrors) => ({ ...errors, priority: "" }));
  };

  //function to handle all type frequency. e.g. - daily, hourly,
  const handleFreqcy = (e) => {
    // console.log(e);
    setHrs([]);
    setDaysOfWeek([]);
    setSchedule((prevState) => {
      prevState.frequency.type = e;
      prevState.frequency.mits_of_hrs = [];
      prevState.frequency.hrs_of_day = [];
      prevState.frequency.days_of_week = [];
      return { ...prevState };
    });
    e === ("repeat_minutes" || "repeat_days" || "repeat_hours") &&
      setSchedule((prevState) => {
        prevState.frequency.type_value = "1";
        return { ...prevState };
      });
    setErrors((prevErrors) => ({ ...errors, frequency: "" }));
  };

  //function to handle frequnecy value
  const handleInsptnFreqcy = (e) => {
    setSchedule((prevState) => {
      const newState = { ...prevState };
      newState.frequency.type_value = e;
      return newState;
    });
    setErrors((prevErrors) => ({ ...prevErrors, frequencyValue: "" }));
  };

  // conver ISO time to UTC
  const convertToUTC = (time) => {
    const localTime = new Date("1970-01-01T" + time + ":00");
    const utcTime = localTime.toISOString().substr(11, 5);
    return utcTime;
  };

  // function to handle days of week in any repeat frequency
  const handleDay = (e) => {
    // console.log(e);
    const newDays = [...daysOfWeek];
    const index = newDays.indexOf(e);
    if (index === -1) {
      newDays.push(e);
    } else {
      newDays.splice(index, 1);
    }
    setDaysOfWeek(newDays.sort((a, b) => a - b));
    setSchedule((prevState) => {
      prevState.frequency.days_of_week = newDays.sort((a, b) => a - b);
      prevState.frequency.type_value = "";
      return { ...prevState };
    });
    // Clear the error if there is at least one hour selected
    if (newDays?.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, days_of_week: "" }));
    }
  };

  // function to handle hours of all the selected days in any repeat frequency
  const handlehr = (localTime) => {
    const utcTime = convertToUTC(localTime);
    const newHrs = [...hrs];
    const hours = newHrs.indexOf(utcTime);
    if (hours === -1) {
      newHrs.push(utcTime);
    } else {
      newHrs.splice(hours, 1);
    }
    // console.log(newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b)));
    setHrs(
      newHrs.sort(
        (a, b) => new Date("1970-01-01T" + a) - new Date("1970-01-01T" + b)
      )
    );
    setSchedule((prevState) => {
      prevState.frequency.hrs_of_day = newHrs.sort(
        (a, b) => new Date("1970-01-01T" + a) - new Date("1970-01-01T" + b)
      );
      prevState.frequency.type_value = "";
      return { ...prevState };
    });
    // Clear the error if there is at least one hour selected
    if (newHrs?.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, hrs_of_day: "" }));
    }
  };

  // function to handle frequnecy end type - No end, End After Occurences, End Date
  const handleFreqEnd = (e) => {
    const getshow = e.target.value;
    setSchedule((prevState) => {
      prevState.frequency.end_type = getshow;
      return { ...prevState };
    });
    setErrors((prevErrors) => ({ ...errors, endType: "" }));
  };

  // function handle answer type - dropdown
  const validateDropdownOptions = (checkpoints) => {
    let hasError = false;
    const errors = {};

    checkpoints.forEach((checkpoint, i) => {
      checkpoint.dropdown_array.forEach((option, optionIndex) => {
        if (option.trim() === "") {
          if (!errors[i]) errors[i] = [];
          errors[i][optionIndex] = "Option cannot be empty";
          hasError = true;
        } else if (errors[i]) {
          errors[i][optionIndex] = null;
        }
      });
    });

    return { hasError, errors };
  };

  const handleDropdownOptionChange = (e, i, optionIndex) => {
    const value = e.target.value;
    // console.log(value);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array[optionIndex] = value;
      return { ...prevState };
    });
    // Validate after change
    const { errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  const addDropdownOption = (i) => {
    // console.log(schedule);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.push("");
      return { ...prevState };
    });
    // Validate after adding new option
    const { errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  const removeDropdownOption = (i, optionIndex) => {
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.splice(optionIndex, 1);
      return { ...prevState };
    });
    // Validate after removing option
    const { hasError, errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  // function to add multiple task/checkpoints card
  const addInspChekPonts = () => {
    let newCpObj = {
      checkpoint: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
      dropdown_array: [],
      attachment: "",
    };
    let cpList = schedule.checkpoints;
    let updatedList = cpList.push(newCpObj);
    setSchedule({ ...schedule, updatedList });
  };

  //function to delete Task
  const deleteInspChekPonts = (index) => {
    // Show a confirmation dialog
    if (window.confirm("Are you sure you wish to delete?")) {
      // If the user confirms, proceed with deletion
      let afterDelCp = [...schedule.checkpoints];
      afterDelCp.splice(index, 1);
      setSchedule({ ...schedule, checkpoints: afterDelCp });
    } else {
      // If the user cancels, do nothing
      // console.log("Deletion cancelled");
    }
  };

  const deleteCheckPoints = (taskIndex, itemIndex) => {
    if (window.confirm("Are you sure you wish to delete?")) {
      let taskItemList = schedule.maintenance_activities[taskIndex].checkpoints;
      taskItemList.splice(itemIndex, 1);
      setSchedule({ ...schedule, checkpoints: taskItemList });
    } else {
      // If the user cancels, do nothing
      // console.log("Deletion cancelled");
    }
  };

  const deleteTask = (index) => {
    if (window.confirm("Are you sure you wish to delete?")) {
      let afterDelTask = schedule.maintenance_activities;
      afterDelTask.splice(index, 1);
      setSchedule({ ...schedule, maintenance_activities: afterDelTask });
    } else {
      // If the user cancels, do nothing
      // console.log("Deletion cancelled");
    }
  };

  const addCheckPoints = (maIndex) => {
    let newcpObj = {
      checkpoint: "",
      approval_sequence: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
    };
    let taskList = schedule.maintenance_activities;
    taskList.forEach((mactvty, j) => {
      if (j === maIndex) {
        mactvty.checkpoints.push(newcpObj);
      }
    });
    let updatedTaskList = [...taskList];
    setSchedule({ ...schedule, maintenance_activities: updatedTaskList });
    setErrors((prevErrors) => ({ ...errors, add_checkpoint: [] }));
  };
  // function to store attachments of inspection activities
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  ///////////////////////////////////// form validation  /////////////////////////////////////////////
  const [errors, setErrors] = useState({
    name: "",
    type: "",
    priority: "",
    basedOn: "",
    managedBy: "",
    frequency: "",
    checkpoint: [],
    stdReq: [],
    ansTyp: [],
    dropdownOptions: [],
    hrs_of_day: [],
    days_of_week: [], //["Sun", "Mon", "Tue", ....]
    frequencyValue: "",
    endType: "",
    occur_value: "",
    date_value: "",
    add_activies: "",
    add_checkpoint: [],
    approval_category: [],
    approval_type: [],
    parallel_type: [],
    approval_set: [],
    approval_set_for_parallel: [],
  });
  const formValidation = () => {
    const newErrors = {
      name: "",
      type: "",
      priority: "",
      basedOn: "",
      managedBy: "",
      frequency: "",
      checkpoint: new Array(schedule.checkpoints?.length).fill(""),
      stdReq: new Array(schedule.checkpoints?.length).fill(""),
      ansTyp: new Array(schedule.checkpoints?.length).fill(""),
      dropdownOptions: new Array(schedule.checkpoints?.length).fill(""),
      hrs_of_day: new Array(schedule.frequency.hrs_of_day?.length).fill(""),
      days_of_week: new Array(schedule.frequency.days_of_week?.length).fill(""), //["Sun", "Mon", "Tue", ....]
      frequencyValue: "",
      endType: "",
      add_activies: "",
      add_checkpoint: new Array(schedule.checkpoints?.length).fill(""),
      approval_category: [],
      approval_type: [],
      parallel_type: [],
      approval_set: [],
      approval_set_for_parallel: [],
    };
    let formIsValid = true;

    if (!schedule.inspection_name) {
      newErrors.name = `Please Enter ${type} Name`;
      formIsValid = false;
    }
    if (!schedule.type && type === "Maintenance") {
      newErrors.type = `Please Select ${type} Type`;
      formIsValid = false;
    }
    if (!schedule.priority) {
      newErrors.priority = "Please Choose Priority";
      formIsValid = false;
    }
    if (!schedule.based_on) {
      newErrors.basedOn = "Please Choose Based on";
      formIsValid = false;
    }
    if (!schedule.managed_by) {
      newErrors.managedBy = "Please Choose Managed by";
      formIsValid = false;
    }
    if (schedule.based_on === "calendar" && !schedule.frequency?.type) {
      newErrors.frequency = "Please Choose Frequency";
      formIsValid = false;
    }
    if (
      schedule.based_on === "calendar" &&
      !schedule.frequency?.type_value &&
      schedule?.frequency?.type !== "repeat_minutes" &&
      schedule.frequency.type !== "repeat_hours" &&
      schedule.frequency.type !== "repeat_days"
    ) {
      newErrors.frequencyValue = "Please Enter Frequency Value";
      formIsValid = false;
    }
    if (schedule.based_on === "calendar" && !schedule.frequency?.end_type) {
      newErrors.endType = `Please Choose ${type} End Type`;
      formIsValid = false;
    }
    if (
      schedule.frequency?.end_type === "end_after_occurence" &&
      !schedule.frequency?.occur_value
    ) {
      newErrors.endType = "Please Enter Occurence Value";
      formIsValid = false;
    }
    if (
      schedule.frequency?.end_type === "end_date" &&
      !schedule.frequency?.date_value
    ) {
      newErrors.endType = "Please Enter End Date";
      formIsValid = false;
    }
    if (
      (schedule.frequency.type === "repeat_days" ||
        schedule.frequency.type === "repeat_hours") &&
      schedule.frequency?.days_of_week?.length === 0
    ) {
      newErrors.days_of_week = "Please Select Atleast One Day of Week";
      formIsValid = false;
    }
    if (
      schedule.frequency.type === "repeat_hours" &&
      schedule.frequency?.hrs_of_day?.length === 0
    ) {
      newErrors.hrs_of_day = "Please Select Atleast One Hour of Day";
      formIsValid = false;
    }
    // Validation 1: Check if type is "Maintenance" and no activities added
    if (
      type === "Maintenance" &&
      schedule.maintenance_activities?.length === 0
    ) {
      newErrors.add_activies = "Please Add at least One Activity.";
      formIsValid = false;
    }
    // Validation 2: Check if activities are present but `ma_type === maintenance_checkpoint` is missing
    if (
      schedule.maintenance_activities?.length > 0 &&
      !schedule.maintenance_activities.some(
        (activity) => activity.ma_type === "maintenance_checkpoint"
      )
    ) {
      newErrors.add_activies = "Please Add Maintenance Checkpoint.";
      formIsValid = false;
    }
    if (type === "Inspection") {
      schedule.checkpoints.forEach((cp, i) => {
        if (!cp.checkpoint) {
          newErrors.checkpoint[i] = "Please Enter Checkpoint";
          formIsValid = false;
        }
        if (!cp.standard_requirement) {
          newErrors.stdReq[i] = "Please Enter Standard Requirement";
          formIsValid = false;
        }
        if (!cp.answer_type) {
          newErrors.ansTyp[i] = "Please Choose Answer Type";
          formIsValid = false;
        }
        if (cp.answer_type === "dropdown") {
          if (cp.dropdown_array?.length === 0) {
            newErrors.ansTyp[i] = "Please Add at least One Dropdown Option";
            formIsValid = false;
          }
        }
        // Validate individual dropdown options
        const emptyOptions = cp.dropdown_array.filter(
          (option) => option.trim() === ""
        );
        if (emptyOptions?.length > 0) {
          newErrors.dropdownOptions[i] = "Please Fill All Dropdown Options";
          formIsValid = false;
        }
      });
    }
    // validation for maintenance checkpoit all three activity
    if (type === "Maintenance") {
      schedule.maintenance_activities.forEach((each, maIndex) => {
        // console.log(each, "each");
        if (
          each.ma_type === "maintenance_checkpoint" ||
          each.ma_type === "pre_maintenance_check" ||
          each.ma_type === "post_maintenance_check"
        ) {
          each.checkpoints.forEach((cp, cpIndex) => {
            // console.log(cp, "cp");
            // Initialize array for this specific maintenance activity if not already initialized
            if (!newErrors.checkpoint[maIndex]) {
              newErrors.checkpoint[maIndex] = [];
            }
            if (!newErrors.stdReq[maIndex]) {
              newErrors.stdReq[maIndex] = [];
            }
            if (!newErrors.ansTyp[maIndex]) {
              newErrors.ansTyp[maIndex] = [];
            }
            // Check and add error for each checkpoint
            if (!cp.checkpoint) {
              newErrors.checkpoint[maIndex][cpIndex] =
                "Please Enter Checkpoint";
              formIsValid = false;
            }
            if (!cp.standard_requirement) {
              newErrors.stdReq[maIndex][cpIndex] =
                "Please Enter Standard Requirement";
              formIsValid = false;
            }
            if (!cp.answer_type) {
              newErrors.ansTyp[maIndex][cpIndex] = "Please Choose Answer Type";
              formIsValid = false;
            }
          });
          // Check if there are no checkpoints
          if (each.checkpoints?.length === 0) {
            newErrors.add_checkpoint[maIndex] =
              "Please Add at least one Checkpoint";
            formIsValid = false;
          }
        }
        if (
          each.ma_type === "pre_maintenance_approval" ||
          each.ma_type === "post_maintenance_approval"
        ) {
          // console.log(`${each.ma_type} detected for index`, maIndex);
          // console.log("Approvals data:", each.approvals);
          // Initialize approvals object if not already initialized
          if (!newErrors[maIndex]) {
            newErrors[maIndex] = {
              approval_category: "",
              approval_type: "",
              parallel_type: "",
              approval_set: "",
              approval_set_for_parallel: "",
            };
          }
          // if (!newErrors.approval_category[maIndex]) {
          //   newErrors.approval_category[maIndex] = [];
          // }
          // if (!newErrors.approval_type[maIndex]) {
          //   newErrors.approval_type[maIndex] = [];
          // }
          // if (!newErrors.parallel_type[maIndex]) {
          //   newErrors.parallel_type[maIndex] = [];
          // }
          // if (!newErrors.approval_set[maIndex]) {
          //   newErrors.approval_set[maIndex] = [];
          // }
          // if (!newErrors.approval_set_for_parallel[maIndex]) {
          //   newErrors.approval_set_for_parallel[maIndex] = [];
          // }

          // Check approval category
          if (each.ma_type === "pre_maintenance_approval") {
            if (!each.approvals.approval_category) {
              newErrors[maIndex].approval_category =
                "Please Select Approval Category";
              formIsValid = false;
            }
          }
          // console.log(
          //   each.approvals.approval_category,
          //   "each.approvals.approval_categoryeach.approvals.approval_category"
          // );

          // Apply operational approval logic for both types
          // Validate approval type (parallel or sequence)
          if (
            !each.approvals.parallel_approval_type &&
            !each.approvals.sequence_approval_type
          ) {
            newErrors[maIndex].approval_type =
              "Please Select Operational Category";
            formIsValid = false;
          }
          // Validate parallel type
          if (
            each.approvals.parallel_approval_type === "parallel" &&
            !each.approvals.parallel_type
          ) {
            newErrors[maIndex].parallel_type =
              "Please Select Parallel Category";
            formIsValid = false;
          }
          // Validate approval set
          if (
            !each.approvals.parallel_approval_type &&
            each.approvals.approval_set?.length === 0
          ) {
            newErrors[maIndex].approval_set = "Please Select at least One User";
            formIsValid = false;
          }
          // Validate approval set for parallel type
          if (
            each.approvals.parallel_approval_type === "parallel" &&
            each.approvals.parallel_type &&
            each.approvals.approval_set?.length === 0
          ) {
            newErrors[maIndex].approval_set_for_parallel =
              "Please Select at least One User";
            formIsValid = false;
          }
        }
        // Log final error object for the current index
        // console.log("newErrors for index", maIndex, newErrors);
      });
    }

    setErrors(newErrors);
    return formIsValid;
  };
  function sortByAcendingOrder(objectArray) {
    return objectArray.sort(
      (prev, curr) => Number(prev.ma_sequence) - Number(curr.ma_sequence)
    );
  }
  const addMaintChekpnts = (taskType, taskSeq) => {
    let newMaObj = {
      ma_type: taskType,
      ma_name: "",
      ma_sequence: taskSeq,
      ma_sla: "",
      ma_sla_interval: "",
      ma_sop: "",
      ma_assignee_type: "",
      ma_assignee: [],
      ma_status: "",
      ma_attachment: "",
      created_by: "",
      modified_by: "",
      checkpoints: [],
      approvals: {
        approval_category: "",
        approval_type: "",
        approval_set: [],
      },
    };
    let updatedMaList = [...schedule.maintenance_activities, newMaObj];
    const uniqueValues = new Set(updatedMaList?.map((v) => v.ma_type));
    if (uniqueValues.size < updatedMaList?.length) {
      return { ...schedule };
    }
    const groupedTasks = sortByAcendingOrder(updatedMaList);
    setSchedule({ ...schedule, maintenance_activities: groupedTasks });
    setErrors((prevErrors) => ({ ...errors, add_activies: "" }));
  };

  // console.log(schedule, "schedule");

  // Function to assign the SOP
  const assigned = (selectedActivity) => {
    const arrayFromString = viewPdf?.split(",");
    let SOPdata = {
      maintenance_activities: {
        ma_type: selectedActivity,
      },
      is_sop: true,
      name: arrayFromString[1],
      url: arrayFromString[0],
      assigned_by: userid,
    };
    // console.log("SOPdata", SOPdata);
    dispatch({
      type: "ASSIGN_SOP_MAIN",
      payload: [SOPdata, id],
    });
  };

  const submitInspChekPonts = (e) => {
    e.preventDefault();
    // Perform form validation and check if the form is valid
    const isFormValid = formValidation();
    //  function to create the inspection
    // console.log(isFormValid);

    // function to upload the maintenance activity
    if (isFormValid && operation === "upload" && type === "Maintenance") {
      let scheduleData = {
        parent_mid: schedule.parent_mid,
        maintenance_name: schedule.inspection_name,
        maintenance_type: schedule.type,
        priority: schedule.priority,
        based_on: schedule.based_on,
        managed_by: schedule.managed_by,
        assignee: schedule.assignee,
        maintenance_duration: schedule.inspection_duration?Number(schedule.inspection_duration):null,
        shutdown_duration: schedule.shut_down_duration?Number(schedule.shut_down_duration):null,
        man_power_required: schedule.man_power_required?Number(schedule.man_power_required):null,
        man_hour_required: schedule.man_hour_required?Number(schedule.man_hour_required):null,
        frequency: {
          frequency_type: schedule?.frequency?.type,
          frequency_type_value: schedule?.frequency?.type_value?Number(schedule.frequency.type_value):null,
          frequency_end_type: schedule?.frequency?.end_type,
          frequency_end_occurence_value: schedule?.frequency?.occur_value,
          frequency_end_date_value: schedule?.frequency?.date_value,
        },
        maintenance_activities: schedule.maintenance_activities,
        created_by: userid,
      };
      // submit response to parent for backedn API
      submitData_upload(scheduleData);
    }
    // function to update the maintenance activity
    if (isFormValid && operation === "update" && type === "Maintenance") {
      let updateData = {
        parent_mid: maintGetSingle.parent_mid,
        maintenance_name: schedule.inspection_name,
        maintenance_type: schedule.type,
        priority: schedule.priority,
        based_on: schedule.based_on,
        managed_by: schedule.managed_by,
        assignee: schedule.assignee,
        maintenance_duration: schedule.inspection_duration?Number(schedule.inspection_duration):null,
        shutdown_duration: schedule.shut_down_duration?Number(schedule.shut_down_duration):null,
        man_power_required: schedule.man_power_required?Number(schedule.man_power_required):null,
        man_hour_required: schedule.man_hour_required?Number(schedule.man_hour_required):null,
        frequency: {
          frequency_type: schedule?.frequency?.type,
          frequency_type_value: schedule?.frequency?.type_value?Number(schedule.frequency.type_value):null,
          frequency_end_type: schedule?.frequency?.end_type,
          frequency_end_occurence_value: schedule?.frequency?.occur_value,
          frequency_end_date_value: schedule?.frequency?.date_value,
        },
        maintenance_activities: schedule.maintenance_activities,
        created_by: userid,
        modified_by: userid,
      };
      submitData_update(updateData);
    }
  };

  useEffect(() => {
    if (maintGetSingle && operation !== "upload" && type === "Maintenance") {
      // console.log("maintGetSingle", maintGetSingle);
      setSchedule({
        parent_mid: maintGetSingle.parent_mid,
        inspection_name: maintGetSingle.maintenance_name,
        type: maintGetSingle.maintenance_type,
        priority: maintGetSingle.priority,
        based_on: maintGetSingle.based_on,
        managed_by: maintGetSingle.managed_by,
        // assignee: inspGetSingle.assignee,
        inspection_duration: maintGetSingle.maintenance_duration,
        shut_down_duration: maintGetSingle.shutdown_duration,
        man_power_required: maintGetSingle.man_power_required,
        man_hour_required: maintGetSingle.man_hour_required,
        frequency: {
          type: maintGetSingle.frequency.frequency_type, //"months",
          type_value: maintGetSingle.frequency.frequency_type_value,
          end_type: maintGetSingle.frequency.frequency_end_type,
          occur_value: maintGetSingle.frequency.frequency_end_occurence_value,
          date_value: maintGetSingle.frequency.frequency_end_date_value,
          // days_of_week: inspGetSingle?.frequency?.frequency_days_of_week,
          // hrs_of_day: inspGetSingle.frequency.frequency_hours_of_day,
          // mits_of_hrs: inspGetSingle.frequency.frequency_minutes_of_hours,
        },
        maintenance_activities: maintGetSingle.maintenance_activities,
      });
      setShowhide(maintGetSingle.priority);
      // console.log(inspGetSingle?.frequency?.frequency_days_of_week, "data");
    }
  }, [maintGetSingle, id]);

  // useEffect
  useEffect(() => {
    if (isSubmitted === true && type === "Maintenance") {
      navigate("/maintenancelist", { state: { key } });
    }
  }, [isSubmitted]);

  useEffect(() => {
    // console.log(queryParams.get("type"));
  }, [queryParams]);

  useEffect(() => {
    const roleCode = role[0];
    if (accessOnDemand === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [accessOnDemand]);

  useEffect(() => {
    if (queryParams.get("type")) {
      setKey(queryParams.get("type"));
    }
  }, [queryParams.get("type")]);

  // //functions for Character Validation
  // function nameChars(str) {
  //   const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
  //   return specialChars.test(str);
  // }
  // function timeChars(str) {
  //   if (str === null) {
  //     return true;
  //   }
  //   const integers = /^[1-9][0-9]{0,9}$/;
  //   return integers.test(str);
  // }

  // console.log(formdata, "formdata");

  return (
    <div>
      {isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="d-flex">
            <div className="col-md-6">
              <form className="mt-5 border p-4 bg-light shadow rounded">
                {/* Add Schedule form starts */}
                <h3 style={{ textAlign: "center" }}>
                  {operation === "upload"
                    ? `Create ${type.charAt(0).toUpperCase()}${type.slice(1)}`
                    : operation === "view"
                    ? `View ${type.charAt(0).toUpperCase()}${type.slice(1)}`
                    : operation === "update"
                    ? `Edit ${type.charAt(0).toUpperCase()}${type.slice(1)}`
                    : ""}
                </h3>
                <div>
                  {formdata?.activity_name?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>
                        {formdata?.activity_name?.lable}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="inspectionname"
                        className="form-control"
                        maxLength='200'
                        placeholder={formdata?.activity_name?.placeholder}
                        value={schedule?.inspection_name}
                        onChange={(e) => {
                          setSchedule({
                            ...schedule,
                            inspection_name: e.target.value,
                          });
                          setErrors((prevErrors) => ({ ...errors, name: "" }));
                        }}
                        disabled={operation === "view" ? true : false}
                      />
                      {errors.name && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.name}
                        </div>
                      )}
                      {/* {nameChars(schedule.inspection_name) ||
                    schedule.inspection_name === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Inspection Name
                          </div>
                        ))} */}
                    </div>
                  )}
                  {formdata?.type?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>
                        {formdata?.type?.lable}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="type"
                        className="form-select"
                        value={schedule?.type}
                        onChange={(e) => {
                          setSchedule({
                            ...schedule,
                            type: e.target.value,
                          });
                          setErrors((prevErrors) => ({ ...errors, type: "" }));
                        }}
                        disabled={operation === "view" ? true : false}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value={formdata?.type?.options[0].val}>
                          {formdata?.type?.options[0].lable}
                        </option>
                        <option value={formdata?.type?.options[1].val}>
                          {formdata?.type?.options[1].lable}
                        </option>
                        <option value={formdata?.type?.options[2].val}>
                          {formdata?.type?.options[2].lable}
                        </option>
                      </select>
                      {errors.type && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.type}
                        </div>
                      )}
                    </div>
                  )}
                  {formdata?.priority?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>
                        {formdata?.priority?.lable}
                        <span className="text-danger">*</span>
                      </label>
                      &nbsp;
                      {formdata?.priority?.options?.map((option, index) => (
                        <React.Fragment key={index}>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: index === 0 ? "0px" : "25px" }}
                            type="radio"
                            name={option.val}
                            value={option.val}
                            checked={showhide === option.val}
                            onChange={handleShow}
                            disabled={operation === "view" ? true : false}
                          />
                          &nbsp;{option.lable}
                        </React.Fragment>
                      ))}
                      {/* <input
                        className="radioButtons"
                        style={{ marginLeft: "25px" }}
                        type="radio"
                        name="asset"
                        value="low"
                        checked={showhide === "low" ? true : false}
                        onClick={handleShow}
                        disabled={operation === "view" ? true : false}
                      />
                      &nbsp;Low
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px" }}
                        type="radio"
                        name="asset"
                        value="medium"
                        checked={showhide === "medium" ? true : false}
                        onClick={handleShow}
                        disabled={operation === "view" ? true : false}
                      />
                      &nbsp; Medium
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px" }}
                        type="radio"
                        name="asset"
                        value="high"
                        checked={showhide === "high" ? true : false}
                        onClick={handleShow}
                        disabled={operation === "view" ? true : false}
                      />
                      &nbsp; High */}
                      {errors.priority && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.priority}
                        </div>
                      )}
                    </div>
                  )}
                  {formdata?.based_on?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>
                        {formdata?.based_on?.lable}
                        <span className="text-danger">*</span>
                      </label>
                      {accessOnDemand?.c === true && type === "inspection" ? (
                        <select
                          name="based_on"
                          className="form-select"
                          value={schedule.based_on}
                          disabled={operation === "view" ? true : false}
                          onChange={(e) => {
                            setSchedule({
                              ...schedule,
                              based_on: e.target.value,
                            });
                            setErrors((prevErrors) => ({
                              ...errors,
                              basedOn: "",
                            }));
                          }}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="calendar">Calendar</option>
                          <option value="on_demand">On Demand</option>
                        </select>
                      ) : (
                        <select
                          name="based_on"
                          className="form-select"
                          value={schedule.based_on}
                          onChange={(e) => {
                            setSchedule({
                              ...schedule,
                              based_on: e.target.value,
                            });
                            setErrors((prevErrors) => ({
                              ...errors,
                              basedOn: "",
                            }));
                          }}
                          disabled={operation === "view" ? true : false}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {formdata?.based_on?.options?.map((option, index) => (
                            <option value={option.val}>{option.lable}</option>
                          ))}
                        </select>
                      )}
                      {errors.basedOn && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.basedOn}
                        </div>
                      )}
                    </div>
                  )}
                  {formdata?.managed_by?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>
                        {formdata?.managed_by?.lable}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="managed_by"
                        className="form-select"
                        value={schedule.managed_by}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) => {
                          setSchedule({
                            ...schedule,
                            managed_by: e.target.value,
                          });
                          setErrors((prevErrors) => ({
                            ...errors,
                            managedBy: "",
                          }));
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {formdata?.managed_by?.options?.map((option, index) => (
                          <>
                            <option value={option.val}>{option.lable}</option>
                          </>
                        ))}
                      </select>
                      {errors.managedBy && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.managedBy}
                        </div>
                      )}
                    </div>
                  )}
                  {formdata?.main_duration?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>{formdata?.main_duration?.lable}</label>
                      <input
                        min="0"
                        name="duration"
                        className="form-control"
                        maxLength="4"
                        placeholder={formdata?.main_duration?.placeholder}
                        value={schedule.inspection_duration}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) =>{
                          if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            inspection_duration: e.target.value,
                          })
                        }}}
                      />
                      {/* {timeChars(schedule.inspection_duration) ||
                    schedule.inspection_duration === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))} */}
                    </div>
                  )}
                  {formdata?.shut_down_duration?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>{formdata?.shut_down_duration?.lable}</label>
                      <input
                        type="text"
                        name="shut_down_duration"
                        className="form-control"
                        maxLength='4'
                        placeholder={formdata?.shut_down_duration?.placeholder}
                        value={schedule.shut_down_duration}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) =>{
                          if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            shut_down_duration: e.target.value,
                          })
                        }}}
                      />
                    </div>
                  )}
                  {formdata?.man_power?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>{formdata?.man_power?.lable}</label>
                      <input
                        type="text"
                        maxLength='4'
                        name="man_power"
                        className="form-control"
                        placeholder={formdata?.man_power?.placeholder}
                        value={schedule.man_power_required}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) =>{
                          if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            man_power_required: e.target.value,
                          })
                        }}}
                      />
                      {/* {timeChars(schedule.man_power_required) ||
                    schedule.man_power_required === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required man power
                          </div>
                        ))} */}
                    </div>
                  )}
                  {formdata?.man_hour?.lable && (
                    <div className="mb-2 col-md-12">
                      <label>{formdata?.man_hour?.lable}</label>
                      <input
                        type="text"
                        name="man_hour"
                        maxLength="4"
                        className="form-control"
                        placeholder={formdata?.man_hour?.placeholder}
                        value={schedule.man_hour_required}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) =>{
                          if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            man_hour_required: e.target.value,
                          })
                        }}}
                      />
                      {/* {timeChars(schedule.man_hour_required) ||
                    schedule.man_hour_required === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required time
                          </div>
                        ))} */}
                    </div>
                  )}
                  {type === "Inspection" && (
                    <>
                      <div className="mb-2 col-md-12">
                        <label>SLA</label>
                        <input
                          min="0"
                          maxLength='4'
                          name="man_hour"
                          className="form-control"
                          placeholder="Total man hour"
                          value={schedule.sla}
                          disabled={operation === "view" ? true : false}
                          onChange={(e) =>{
                              if (/^\d*$/.test(e.target.value)) {
                            setSchedule({
                              ...schedule,
                              sla: e.target.value,
                            })
                          }}}
                        />
                        {/* {timeChars(schedule.sla) || schedule.sla === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid SLA
                          </div>
                        ))} */}
                      </div>
                      <div className="mb-2 col-md-12">
                        <label>SLA Interval</label>
                        <select
                          name="sla_interval"
                          className="form-select"
                          value={schedule.sla_interval}
                          disabled={operation === "view" ? true : false}
                          onChange={(e) =>
                            setSchedule({
                              ...schedule,
                              sla_interval: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="hours">Hour</option>
                          <option value="minutes">Minute</option>
                        </select>
                      </div>
                    </>
                  )}

                  {type === "Inspection" && (
                    <div className="mb-2 col-md-12">
                      <label>Type</label>
                      <select
                        name="type"
                        className="form-select"
                        value={schedule.type}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) =>
                          setSchedule({
                            ...schedule,
                            type: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        <option value="water">Water Consumption</option>
                        <option value="lpg">LPG Consumption</option>
                        <option value="electric_energy_grid">
                          Electric Energy - Grid
                        </option>
                        <option value="electric_energy_dg">
                          Electric Energy - DG
                        </option>
                        <option value="diesel">Diesel Consumption</option>
                        <option value="steam">Steam Consumption</option>
                        <option value="compressed_air">
                          Compressed Air Consumption
                        </option>
                        <option value="chilled_water">
                          Chilled Water Consumption
                        </option>
                        <option value="other">Other Consumption</option>
                      </select>
                    </div>
                  )}
                  <button
                    className="backButton m-2"
                    type="button"
                    // onClick={() =>
                    //   navigate(
                    //     operation === "upload" && type === "Inspection"
                    //       ? "/assetlist"
                    //       : "/inspectionlist",
                    //     { state: { key } },
                    //     operation === "upload" && type === "Maintenance"
                    //       ? "/assetlist"
                    //       : "/maintenancelist",
                    //     { state: { key } }
                    //   )
                    // }
                    onClick={() => {
                      if (operation === "upload" && type === "Inspection") {
                        navigate("/assetlist", { state: { key } });
                      } else if (
                        operation === "upload" &&
                        type === "Maintenance"
                      ) {
                        navigate("/assetlist", { state: { key } });
                      } else if (type === "Inspection") {
                        navigate("/inspectionlist", { state: { key } });
                      } else if (type === "Maintenance") {
                        navigate("/maintenancelist", { state: { key } });
                      }
                    }}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>

            {/* Calender Based && Inspection*/}
            {schedule.based_on === "calendar" && (
              <div className="mx-2 col-md-12">
                <div className="col-md-12">
                  <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                    <div className="mb-2 col-md-12">
                      <h5>Calendar based on </h5>
                      <label>
                        Frequency
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="frequency_type"
                        className="form-select"
                        value={schedule.frequency.type}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) => {
                          handleFreqcy(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {type === "Maintenance" ? (
                          <>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="half_yearly">Half yearly</option>
                            <option value="yearly">Yearly</option>
                          </>
                        ) : (
                          <>
                            <option value="daily">Daily</option>
                            <option value="hourly">Hourly</option>
                            <option value="minutes">Minutes</option>
                            {/* <option value="repeat_minutes">Repeat Minutes</option> */}
                            <option value="repeat_hours">Repeat Hours</option>
                            <option value="repeat_days">Repeat Days</option>
                          </>
                        )}
                      </select>
                      {errors.frequency && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.frequency}
                        </div>
                      )}
                      {/* Frequency type === Daily & Hourly */}
                      {schedule.frequency.type !== "repeat_minutes" &&
                        schedule.frequency.type !== "repeat_hours" &&
                        schedule.frequency.type !== "repeat_days" && (
                          <div className="mt-3">
                            <label>Frequency value</label>
                            <span className="text-danger">*</span>
                            <input
                              type="text"
                              maxLength='4'
                              style={{
                                marginTop: 5,
                                height: 35,
                                paddingLeft: "10px",
                                borderRadius: 5,
                                border: "1px solid #ccc",
                              }}
                              // min={1}
                              required
                              className="col-md-12"
                              placeholder="Number of days"
                              value={schedule.frequency.type_value}
                              disabled={operation === "view" ? true : false}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // Allow only digits
                                  handleInsptnFreqcy(value);
                                }
                              }}
                            />
                            {/* {timeChars(schedule.frequency.type_value) ||
                          schedule.frequency.type_value === ""
                            ? " "
                            : (formValidation(),
                              (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    paddingTop: "2px",
                                  }}
                                >
                                  Invalid Frequency type
                                </div>
                              ))} */}
                            {errors.frequencyValue && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {errors.frequencyValue}
                              </div>
                            )}
                          </div>
                        )}
                      {/* Frequency type === Repeat Minutes */}
                      {schedule?.frequency?.type === "repeat_minutes" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table>
                              <tbody>
                                {numOfHours
                                  .reduce((rows, time, index) => {
                                    if (index % 5 === 0) rows.push([]);
                                    rows[rows?.length - 1].push(time);
                                    return rows;
                                  }, [])
                                  ?.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {row?.map((time, cellIndex) => (
                                        <>
                                          <td
                                            key={cellIndex}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <div>
                                              <input
                                                className="hoursAlign"
                                                value={time}
                                                type="checkbox"
                                                disabled={
                                                  operation === "view"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handlehr(e.target.value)
                                                }
                                                checked={schedule.frequency.mits_of_hrs
                                                  ?.map((utcTime) => {
                                                    const utcDate = new Date(
                                                      `1970-01-01T${utcTime}:00Z`
                                                    );
                                                    const localHours = utcDate
                                                      .getHours()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    const localMinutes = utcDate
                                                      .getMinutes()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    return `${localHours}:${localMinutes}`;
                                                  })
                                                  .includes(time)}
                                              />
                                              <label
                                                style={{
                                                  fontSize: "13px",
                                                  verticalAlign: "middle",
                                                  marginLeft: "3px",
                                                  // marginTop: '22px',
                                                }}
                                              >
                                                {time}
                                              </label>
                                            </div>
                                          </td>
                                        </>
                                      ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {/* Frequency type === Repeat Days */}
                      {schedule.frequency.type === "repeat_days" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                          </div>
                          {errors.days_of_week && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.days_of_week}
                            </div>
                          )}
                        </div>
                      )}
                      {/* Frequency type === Repeat Hours */}
                      {schedule.frequency.type === "repeat_hours" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            {errors.days_of_week && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {errors.days_of_week}
                              </div>
                            )}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table>
                              <tbody>
                                {numOfHours
                                  .reduce((rows, time, index) => {
                                    if (index % 5 === 0) rows.push([]);
                                    rows[rows?.length - 1].push(time);
                                    return rows;
                                  }, [])
                                  ?.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {row?.map((time, cellIndex) => (
                                        <>
                                          <td
                                            key={cellIndex}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <div>
                                              <input
                                                className="hoursAlign"
                                                value={time}
                                                type="checkbox"
                                                onChange={(e) =>
                                                  handlehr(e.target.value)
                                                }
                                                checked={schedule.frequency.hrs_of_day
                                                  ?.map((utcTime) => {
                                                    const utcDate = new Date(
                                                      `1970-01-01T${utcTime}:00Z`
                                                    );
                                                    const localHours = utcDate
                                                      .getHours()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    const localMinutes = utcDate
                                                      .getMinutes()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    return `${localHours}:${localMinutes}`;
                                                  })
                                                  .includes(time)}
                                                disabled={
                                                  operation === "view"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                style={{
                                                  fontSize: "13px",
                                                  verticalAlign: "middle",
                                                  marginLeft: "3px",
                                                  // marginTop: '22px',
                                                }}
                                              >
                                                {time}
                                              </label>
                                            </div>
                                          </td>
                                        </>
                                      ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {errors.hrs_of_day && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.hrs_of_day}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Schedule End types */}
                      <div className="mt-4 md-6">
                        <label>{type} End Type</label>
                        <span className="text-danger">*</span>
                        <div>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="no_end"
                            name="endType"
                            checked={
                              schedule.frequency.end_type === "no_end"
                                ? true
                                : false
                            }
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          No End<br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_after_occurence"
                            checked={
                              schedule.frequency.end_type ===
                              "end_after_occurence"
                                ? true
                                : false
                            }
                            name="endType"
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          End After Occurences
                          {schedule.frequency.end_type ===
                            "end_after_occurence" && (
                            <input
                              type="number"
                              style={{
                                marginLeft: 10,
                                border: "1px solid",
                                borderRadius: 5,
                                margin: "8px",
                              }}
                              min={1}
                              placeholder="Occurences"
                              disabled={operation === "view" ? true : false}
                              value={schedule.frequency.occur_value}
                              onChange={(e) => {
                                setSchedule((prevState) => {
                                  prevState.frequency.occur_value =
                                    e.target.value;
                                  return { ...prevState };
                                });
                                setSchedule((prevState) => {
                                  prevState.frequency.date_value = "";
                                  return { ...prevState };
                                });
                              }}
                            />
                          )}
                          {errors.occur_value && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.occur_value}
                            </div>
                          )}
                          <br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_date"
                            name="endType"
                            checked={
                              schedule.frequency.end_type === "end_date"
                                ? true
                                : false
                            }
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          End Date
                          {schedule.frequency.end_type === "end_date" && (
                            <input
                              style={{
                                marginLeft: 10,
                                width: 190,
                                border: "1px solid",
                                borderRadius: 5,
                                height: 30,
                              }}
                              value={
                                schedule.frequency.date_value
                                  ? new Date(schedule.frequency.date_value)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                              type="date"
                              placeholdertext={"Please Select a Date"}
                              disabled={operation === "view" ? true : false}
                              onChange={(e) => {
                                setSchedule((prevState) => {
                                  prevState.frequency.date_value =
                                    e.target.value;
                                  return { ...prevState };
                                });
                                setSchedule((prevState) => {
                                  prevState.frequency.occur_value = "";
                                  return { ...prevState };
                                });
                              }}
                              min={today}
                            />
                          )}
                          {errors.date_value && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.date_value}
                            </div>
                          )}
                          {errors.endType && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.endType}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          {/* Tasks */}
          {schedule.maintenance_activities &&
            schedule.maintenance_activities?.map((task, i) => (
              // console.log("index", i) &&
              <div key={i} className="col-md-10">
                <div className="mt-4 p-5 bg-light shadow rounded">
                  <div
                    className="col-md-12"
                    style={{
                      // width: "600px",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {task.ma_type === "pre_maintenance_approval" && (
                      <h4>Pre Maintenance Approval</h4>
                    )}
                    {task.ma_type === "pre_maintenance_check" && (
                      <h4>Pre Maintenance Check</h4>
                    )}
                    {task.ma_type === "maintenance_checkpoint" && (
                      <h4>Maintenance checkpoint</h4>
                    )}
                    {task.ma_type === "spare_change" && <h4>Spare Change</h4>}
                    {task.ma_type === "post_maintenance_check" && (
                      <h4>Post Maintenance Check</h4>
                    )}
                    {task.ma_type === "post_maintenance_approval" && (
                      <h4>Post Maintenance Approval</h4>
                    )}
                    <h6>Task number : {i + 1}</h6>
                    <h6>Sequence #{task.ma_sequence}</h6>
                    {/* delete icon */}
                    <div className="mb-2 col-md-2">
                      <button
                        className="delIcons float-end"
                        type="button"
                        onClick={() => deleteTask(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                      </button>
                    </div>
                  </div>
                  <div>
                    {/* <div className="mb-2 col-md-12">
                      <label>
                        Maintenance Activity
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="taskname"
                        className="form-control"
                        placeholder="Activity Name"
                        value={task.ma_name}
                        onChange={(e) => {
                          setSchedule((prevState) => {
                            prevState.maintenance_activities[i].ma_name =
                              e.target.value;
                            return { ...prevState };
                          });
                          // handleAssignee(e.target.value);
                        }}
                      />
                    </div> */}
                    <div className="mb-2 col-md-12">
                      <label>SLA</label>
                      <input
                        min="1"
                        name="sla"
                        maxLength='4'
                        className="form-control"
                        placeholder="SLA"
                        value={task.ma_sla}
                        onChange={(e) =>
                          setSchedule((prevState) => {
                            prevState.maintenance_activities[i].ma_sla =
                              e.target.value;
                            return { ...prevState };
                          })
                        }
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      <label>SLA Interval</label>
                      <select
                        name="interval"
                        className="form-select"
                        value={task.ma_sla_interval}
                        onChange={(e) =>
                          setSchedule((prevState) => {
                            prevState.maintenance_activities[
                              i
                            ].ma_sla_interval = e.target.value;
                            return { ...prevState };
                          })
                        }
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="hours">Hours</option>
                        <option value="minutes">Minutes</option>
                      </select>
                    </div>
                    {(operation === "update" || operation === "view") &&
                      (task.ma_type === "pre_maintenance_check" ||
                        task.ma_type === "maintenance_checkpoint" ||
                        task.ma_type === "post_maintenance_check") && (
                        <>
                          <div className="mb-2 col-md-12">
                            <label>SOP</label>
                            <select
                              name="interval"
                              className="form-select"
                              value={task?.ma_sop}
                              onChange={(e) => {
                                setViewPdf(e.target.value);
                                // setSchedule((prevState) => {
                                //   prevState.maintenance_activities[i].ma_sop =
                                //     e.target.value;
                                //   return { ...prevState };
                                // });
                              }}
                              disabled={operation === "view" ? true : false}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {sopData?.sop?.map((eachData, index) => (
                                <>
                                  {/* <option key={index} value={eachData.sop}>
                                    {eachData.name}
                                  </option> */}
                                  <option
                                    key={index}
                                    value={[eachData.sop, eachData.name]}
                                  >
                                    <span>{eachData.name}</span>
                                  </option>
                                </>
                              ))}
                            </select>
                            <br />
                            {viewPdf && (
                              <button
                                className="addBtn"
                                onClick={() => {
                                  setShowDocModel(true);
                                  setsopSelectedActivity(task?.ma_type);
                                }}
                                disabled={operation === "view" ? true : false}
                              >
                                Assign & View Selected SOP
                              </button>
                            )}
                          </div>
                          <>
                            <h5>List of Mapped SOP with this Activity</h5>
                            {schedule?.maintenance_activities[i]?.sop?.map(
                              (el, index) => (
                                <ul className="sopList">
                                  <li>{el.name}</li>
                                </ul>
                              )
                            )}
                          </>
                        </>
                      )}

                    {(task.ma_type === "pre_maintenance_approval" ||
                      task.ma_type === "post_maintenance_approval") && (
                      <div>
                        <Approvals
                          maintenanceState={schedule}
                          setMaintenenceState={setSchedule}
                          maState={task}
                          maIndex={i}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </div>
                    )}

                    {(task.ma_type === "pre_maintenance_check" ||
                      task.ma_type === "maintenance_checkpoint" ||
                      task.ma_type === "post_maintenance_check" ||
                      task.ma_type === "inspection_checkpoint") && (
                      <div>
                        <CheckPoints
                          maintenanceState={schedule}
                          setMaintenenceState={setSchedule}
                          maState={task}
                          maIndex={i}
                          addCpItem={(maIndex) => addCheckPoints(maIndex)}
                          deleteCpItem={(maIndex, cpIndex) =>
                            deleteCheckPoints(maIndex, cpIndex)
                          }
                          errors={errors}
                          setErrors={setErrors}
                          operation={operation}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {/* Submit Schedule Button */}
          <div className="mt-10 col-md-12 p-2">
            <div
              className="dropdown"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <button
                style={{ fontSize: "14px" }}
                className="float-end addBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Activites
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "1");
                    }}
                  >
                    Pre Maintenance Approval
                  </button>
                </li>
                <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "2");
                    }}
                  >
                    Pre Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="maintenance_checkpoint"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "3");
                    }}
                  >
                    Maintenance Checkpoint
                  </button>
                </li>
                <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "5");
                    }}
                  >
                    Post Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "6");
                    }}
                  >
                    Post Maintenance Approval
                  </button>
                </li>
                {/* <li>
                  <button
                    disabled={operation === "view" ? true : false}
                    className="dropdown-item"
                    type="button"
                    value="spare_change"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "4");
                    }}
                  >
                    Spare Change
                  </button>
                </li> */}
              </ul>
              {errors.add_activies && (
                <div
                  style={{ color: "red", fontSize: "12px", marginTop: "10px" }}
                >
                  {errors.add_activies}
                </div>
              )}
            </div>
          </div>

          {/* Submit Tasks */}
          <div className="mt-10 col-md-12 p-2">
            <button
              type="button"
              className="saveButton float-end"
              onClick={(e) => submitInspChekPonts(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showDocModel && (
        <PdfDocView
          show={showDocModel}
          onHide={() => setShowDocModel(false)}
          viewlink={viewPdf}
          assigned={assigned}
          sopselectedactivity={sopSelectedActivity}
        />
      )}
    </div>
  );
};

export default PlannedActivity;
