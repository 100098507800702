import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
    faPenToSquare,
    faTrash,
    faUserPlus,
    faAngleLeft,
    faAngleRight,
    faClose,
    faContactBook,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faPlus,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { reportService } from '../../services/service.index';
import Commonheader from '../../layouts/common/commonHeader';
import { useSelector } from 'react-redux';


function TemplateReport() {

    const [data, setData] = useState([])

    // ..................Pagination States................
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const selectedSite = useSelector((state) => state.site.selectedSiteList);

    const navigate = useNavigate();


    const fetchReportTemplates = (page, limit, search) => {
        return reportService.getReportTemplate({ page, limit, search })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data);
                }
            })
            .catch((err) => {
            });
    };
    
    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        fetchReportTemplates(currentPage, itemsPerPage, e.target.value);
    };
    
    const handleClearButton = () => {
        setSearchInput("");
        fetchReportTemplates(currentPage, itemsPerPage, '');
    };
    
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
        fetchReportTemplates(1, items, searchInput);
    };

    let totalPages = 0;
    if (data?.total_count !== undefined) {
        totalPages = Math.ceil(data.total_count / itemsPerPage);
    }
   
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
        fetchReportTemplates(currentPage+1,itemsPerPage,searchInput);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
        fetchReportTemplates(currentPage-1,itemsPerPage,searchInput);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchReportTemplates(1,itemsPerPage,searchInput);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
        fetchReportTemplates(totalPages,itemsPerPage,searchInput);
    };
    // Get report list and store in data - setDat
    
    const getListOfReport = async (id) => {
        reportService.getReportTemplate({page:currentPage, limit:itemsPerPage, search:searchInput,site:id}).then((res) => {
            if (res.status === 200) {
                setData(res.data);
            }
        }).catch((err) => {
        })
    }

    useEffect(() => {
        let arr = selectedSite?.selectedSite.map((site) => site._id);
        let id =  Array.isArray(arr) ? arr.join(',') : arr;
        getListOfReport(id)
    }, [selectedSite])


    const handleButtonClick = (el) => {
        // navigate(`/reportform/${el._id}?filename=${encodeURIComponent(el.name)}`)
        navigate(`/reportform/${el._id}?isMulti=${el.isMulti?el.isMulti:false}&frequency=${el?.frequency}&filename=${encodeURIComponent(el.name)}`)
    }

    return (
        <div>
            <Commonheader />
            <div
                className="listHeadings"
                style={{
                    fontSize: "20px",
                    textAlign: 'center'
                }}
            >
            </div>
            <div>
                <div style={{ float: "right" }}>
                    <form
                        className="mb-4"
                    >
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleSearchInputChange}
                            style={{
                                border: "1px solid steelblue",
                                fontSize: "15px",
                                padding: "5px",
                            }}
                        />
                        <span
                            type="reset"
                            onClick={handleClearButton}
                            style={{ paddingLeft: "8px", marginRight: "25px" }}
                        >
                            <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                        </span>
                    </form>
                </div>
                <div style={{ display: 'flex', width: '89%', justifyContent: 'space-between', margin: 'auto', marginTop: '100px' }}>
                    <h3>Select Report Templates</h3>
                </div>
                <table className="table-bordered" style={{ width: '90%', marginTop: '50px', margin: 'auto' }}>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                    width: '70px',
                                    height: '50px'
                                }}
                            >S. No</th>
                            <th style={{
                                textAlign: "left",
                                backgroundColor: "steelblue",
                                padding: "8px",
                                color: "#fff",
                                height: '50px'
                            }}>Report Name</th>
                            <th style={{
                                textAlign: "center",
                                backgroundColor: "steelblue",
                                padding: "8px",
                                color: "#fff",
                                height: '50px',
                                width: '150px'
                            }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {data?.result?.length > 0 && data?.result?.map((el, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", height: '50px' }}> {(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                                    <td style={{ textAlign: "left", height: '50px',padding: "8px", }}>{el.name}</td>
                                    <td style={{ textAlign: "center", height: '50px', width: '60px' }}>
                                        <button
                                           /*  onClick={() => {
                                               navigate(`/reportform/${el?._id}`);
                                               setReportname(el.name)
                                            }} */
                                            /* onClick={() => handleUpdate(eachData._id)} */
                                            onClick={() => handleButtonClick(el)}
                                            
                                            style={{
                                                border: "none",
                                                color: "#2D7DC1",
                                                background: "none",
                                            }}
                                            data-tip
                                            data-for="viewTip"
                                        >
                                            <FontAwesomeIcon icon={faChevronRight} size="lg" />

                                        </button>
                                    </td>

                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            {/* ................Pagination................ */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    padding: "10px",
                }}
            >
                <div className="mb-6">
                    <label>Items per page:</label> &nbsp;
                    <select
                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                        style={{ borderRadius: "8px", cursor: "pointer" }}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <div style={{ paddingRight: "25px" }} className="pagination">
                    <button
                        style={{
                            padding: "5px",
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "#fff",
                        }}
                        disabled={currentPage === 1}
                        onClick={handleFirstPage}
                    >
                        {currentPage === 1 ? (
                            <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                        ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                    </button>
                    <button
                        style={{
                            padding: "5px",
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "#fff",
                        }}
                        disabled={currentPage === 1}
                        onClick={handlePrev}
                    >
                        {/* Prev */}
                        {currentPage === 1 ? (
                            <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                        ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                    </button>
                    <span style={{ padding: "10px" }}>
                        Showing {currentPage} of {totalPages} pages
                    </span>
                    <button
                        style={{
                            padding: "5px",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            border: "none",
                        }}
                        disabled={currentPage === totalPages}
                        onClick={handleNext}
                    >
                        {currentPage === totalPages ? (
                            <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                        ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                    </button>
                    <button
                        style={{
                            padding: "5px",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            border: "none",
                        }}
                        disabled={currentPage === totalPages}
                        onClick={handleLastpage}
                    >
                        {currentPage === totalPages ? (
                            <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                        ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                    </button>
                </div>
            </div>
        </div >
    )
}

export default TemplateReport