import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";


function ticketGetallStatus(data) {
  return serviceBase.get(
    CONSTANTS.TICKET_STATUS + `?type=${data[3]}&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&start_date=${data[5]}&end_date=${data[6]}&site=${data['id']}`
  );
}
function ticketReport(id) {
  return serviceBase.post(CONSTANTS.TICKET_REPORT ,id);
}

export const ticketDetails = {
  ticketGetallStatus,
  ticketReport
};