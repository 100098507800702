//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import IMAGENAME from "../../../medias/media.index";
import Commonheader from "../../../layouts/common/commonHeader";
import { useSelector, useDispatch } from "react-redux";
import './reportsStyle.css'
import AWS from "aws-sdk";
import MaintenanceActivity from "./maintenaceActivity";
import ApprovalActivity from "./approvalActivity";
import S3BucketImage from './s3BucketImages';
import Loader from "../../../components/loader/loader";

/////////////////////////AWS Operations starts/////////////////////////
AWS.config.update({
  region: process.env.REACT_APP_REGION,
  apiVersion: "latest",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});

const s3 = new AWS.S3();
/////////////////////////AWS Operations ends/////////////////////////
const MaintenanceReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState([]);
  const [afterImageSrc, setAfterImageSrc] = useState([]);
  const [custLogo, setCustLogo] = useState("");
  const [key, setKey] = useState('');
  const [selectedItem, setSelectedItem] = useState("Asset");
  // console.log(key, selectedItem);
  const isLoading = useSelector((state) => state.site.isLoading);


  const maintReportDataArray = useSelector(
    (state) => state.maintenance.maintReportData
  );

  // if (maintReportData !== null) {
  //   var inspCompletedTime = moment(maintReportData.maintenance.maint_sch_date)
  //     .utc()
  //     .format("LLLL");
  // }
  // console.log("maintReportData", maintReportData);

  const getMaintReport = (maintId) => {
    let idArray = maintId.split(",")
    dispatch({
      type: "MAINTENANCE_REPORT",
      payload: { maintenance_mid: idArray },
    });
  };


  useEffect(() => {
    // console.log(location.state);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0])
      setSelectedItem(location.state?.[1])
    }
  }, [])
  useEffect(() => {
    setImageSrc([])
    setAfterImageSrc([])
  }, [maintReportDataArray]);

  //useEffects
  useEffect(() => {
    // console.log("maintReportData", maintReportData);
    if (maintReportDataArray === null) {
      getMaintReport(id);
    }
    window.scrollTo(0, 0)
  }, [maintReportDataArray]);

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getMaintReport(id);
    }
    window.scrollTo(0, 0)
  }, []);

  //function to calculate start date(End date-Maintenance Duration)
  function subtractSeconds(date, seconds) {
    date.setSeconds(date.getSeconds() - seconds);
    return date;
  }
  // if (maintReportDataArray !== null) {
  //   const date = new Date(maintReportDataArray?.maintenance?.maint_completed_date);
  //   var newDate = subtractSeconds(date, maintReportData?.maintenance?.maintenance_duration);
  // }

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0])
      setSelectedItem(location.state?.[1])
    }
  }, [])

  const convertToDMS = (coordinate, isLatitude) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude
      ? coordinate >= 0 ? 'N' : 'S'
      : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }

  //////////////////////////////////////HTML////////////////////////////////////////
  return (
    <>
      {isLoading && <Loader />}
      {maintReportDataArray?.length > 0 && maintReportDataArray?.map((maintReportData) => {
        return <div style={{ margin: "auto", width: 1000, marginBottom: "50px", pageBreakAfter: "always" }}>
          {maintReportData && maintReportData?.maintenance?.maint_trans_status === "approved" &&
            <table className="table" style={{ margin: "auto", width: 1000, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
              <thead style={{ width: '100%' }}>
                <tr style={{
                  verticalAlign: "middle"
                }}>
                  <th scope="col" colSpan="1" style={{ border: '1px solid', width: '200px', textAlign: 'center', verticalAlign: 'middle' }}>
                    <img
                      src={IMAGENAME.logo}
                      alt="ZongoVita logo"
                      style={{
                        width: "150px",
                        alignItems: 'center'
                        // padding: '0.2rem',
                        // margin: 'auto',
                      }}
                    /></th>
                  <th colSpan="6" style={{ border: '1px solid', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'normal' }}>
                    {`${maintReportData.site.site_name} - ${maintReportData.site.city}, ${maintReportData?.site?.state}`}
                    <br />
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '16px'
                    }}>
                      {`Report : ${maintReportData.maintenance.maintenance_name}`}
                    </span>
                  </th>
                  <th colSpan="1" style={{ border: '1px solid', width: '200px', textAlign: 'center', verticalAlign: 'middle' }}>
                    <S3BucketImage
                      imageUrl={maintReportData.customer.cust_logo}
                      widthSize={'auto'}
                    />
                  </th>
                </tr>
                <tr style={{
                  verticalAlign: "middle"
                }}>
                  <th colSpan="3" style={{ border: '1px solid' }}>{`Total Maintenance Duration (hh:mm:ss) - 
                    ${moment
                      .utc(
                        moment
                          .duration(
                            maintReportData.maintenance.maintenance_duration,
                            "seconds"
                          )
                          .asSeconds() * 1000
                      )
                      .format("HH:mm:ss")
                    // moment().seconds(insReportData.inspection.inspection_duration).format("hh:mm:ss")
                    }`}</th>
                  <th colSpan="4" style={{ border: '1px solid' }}>{`Approved Date & Time - 
                   ${moment(maintReportData?.maintenance?.maint_completed_date).format("MMMM Do YYYY | hh:mm:ss A")}`}
                  </th>
                  <th colSpan="1" style={{ border: '1px solid', textAlign: 'center' }}>Priority - &nbsp;
                    {maintReportData?.maintenance?.priority?.charAt(0)?.toUpperCase() + maintReportData?.maintenance?.priority?.slice(1)}
                  </th>
                </tr>
              </thead>
            </table>
          }
          {maintReportData && maintReportData?.parent?.parent_id !== '' &&
            <table className="table" style={{ margin: "auto", width: 1000, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
              <thead>
                <tr>
                  <th
                    colSpan="9"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#0862B4",
                      color: "white",
                      textAlign: "center",
                      borderBottom: "none",
                      padding: "7px",
                      borderTop: "1px black",
                      margin: "0px",
                      alignItems: "center",
                    }}
                  >
                    {maintReportData?.parent?.type?.charAt(0).toUpperCase() +
                      maintReportData?.parent?.type?.slice(1)}{" "}
                    Details
                  </th>
                </tr>
                <tr style={{ verticalAlign: "middle" }}>
                  <th
                    colSpan="3"
                    style={{ border: "1px solid", verticalAlign: "middle", wordWrap: "breakWord" }}
                  >
                    {maintReportData?.parent?.type?.charAt(0).toUpperCase() +
                      maintReportData?.parent?.type?.slice(1)}{" "}
                    ID - {maintReportData?.parent?.parent_id}
                  </th>
                  <th
                    colSpan="3"
                    style={{ border: "1px solid", verticalAlign: "middle", wordWrap: "breakWord" }}
                  >
                    {maintReportData?.parent?.type?.charAt(0).toUpperCase() +
                      maintReportData?.parent?.type?.slice(1)}{" "}
                    Name - {maintReportData?.parent?.parent_name}
                  </th>
                  <th
                    colSpan="3"
                    style={{ border: "1px solid", verticalAlign: "middle", wordWrap: "breakWord" }}
                  >
                    {maintReportData?.parent?.type?.charAt(0).toUpperCase() +
                      maintReportData?.parent?.type?.slice(1)}{" "}
                    Location - {maintReportData?.parent?.location}
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan="9"
                    style={{ border: "1px solid", wordWrap: "breakWord" }}
                  >
                    {maintReportData?.parent?.type?.charAt(0).toUpperCase() +
                      maintReportData?.parent?.type?.slice(1)}{" "}
                    Geo Location - Lat : {maintReportData?.parent?.geo_loc?.coordinates?.length > 0 ? convertToDMS(maintReportData?.parent?.geo_loc?.coordinates[1], true) : "NA"} & Long : {maintReportData?.parent?.geo_loc?.coordinates?.length > 0 ? convertToDMS(maintReportData?.parent?.geo_loc?.coordinates[0], false) : "NA"}
                  </th>
                </tr>
              </thead></table>}
          {/* {console.log('pre_maintenance_approval', maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_approval')])} */}
          {/* {console.log('pre_maintenance_approval index', typeof(maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_approval')))} */}
          {(maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_approval') !== -1) &&
            <ApprovalActivity
              MaintenanceActivity={maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_approval')]}
              activityName={"Pre Maintenance Approval"}
            />
          }
          {maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_check') !== -1 &&
            <MaintenanceActivity
              MaintenanceActivity={maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'pre_maintenance_check')]}
              activityName={"Pre Maintenance Check"}
            />
          }
          {maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'maintenance_checkpoint') !== -1 &&
            <MaintenanceActivity
              MaintenanceActivity={maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'maintenance_checkpoint')]}
              activityName={"Maintenance Check"}
            />
          }
          {maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'post_maintenance_check') !== -1 &&
            <MaintenanceActivity
              MaintenanceActivity={maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'post_maintenance_check')]}
              activityName={"Post Maintenance Check"}
            />
          }
          {maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'post_maintenance_approval') !== -1 &&
            <ApprovalActivity
              MaintenanceActivity={maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities.findIndex((item) => item.ma_type === 'post_maintenance_approval')]}
              activityName={"Post Maintenance Approval"}
            />
          }
          <table className="table" style={{ margin: "auto", width: 1000, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
            <thead style={{ width: '100%' }}>
              {maintReportData?.performer &&
                <tr style={{
                  verticalAlign: "middle"
                }}>
                  <th colSpan='3'>
                    {(maintReportData?.performer?.name && maintReportData?.performer?.name !== "undefined undefined undefined") ? (
                      <div>{`Performed by - ${maintReportData?.performer?.name}`}</div>
                    ) : (
                      "Performed by - -"
                    )}
                  </th>
                  <th colSpan='3'>
                    {maintReportData?.performer?.designation ? (
                      <div>{`Designation - ${(maintReportData?.performer?.designation).replace("_", " ")}`}</div>
                    ) : (
                      "Designation - -"
                    )}
                  </th>

                  <th colSpan='3'>
                    {maintReportData?.performer?.email ? (
                      <div>{`Email - ${maintReportData?.performer?.email}`}</div>
                    ) : (
                      "Email - -"
                    )}
                  </th>
                  <tr><th colSpan='3'>
                    {maintReportData?.performer?.email ? (
                      <div>{`Email - ${maintReportData?.performer?.email}`}</div>
                    ) : (
                      "Email - -"
                    )}
                  </th>
                  </tr>
                </tr>
              }
              {/* {console.log("maintReportData",maintReportData?.maint_activities?.maintenance_activities?.length)} */}
              {maintReportData?.approver?.name && maintReportData?.maint_activities?.maintenance_activities[maintReportData?.maint_activities?.maintenance_activities?.length - 1]?.ma_type !== 'post_maintenance_approval' &&
                <>
                  <tr style={{
                    verticalAlign: "middle"
                  }}>
                    <th colSpan='3'>
                      {maintReportData?.approver?.name ? (
                        <div>{`Approved by - ${maintReportData?.approver?.name}`}</div>
                      ) : (
                        "Approved by - NA"
                      )}
                    </th>
                    <th colSpan='3'>
                      {maintReportData?.approver.designation ? (
                        <div>{`Designation - ${(maintReportData?.approver.designation).replace("_", " ")}`}</div>
                      ) : (
                        "Designation - NA"
                      )}
                    </th>
                    <th colSpan='3'>
                      {maintReportData?.approver.email ? (
                        <div>{`Email - ${maintReportData?.approver.email}`}</div>
                      ) : (
                        "Email - NA"
                      )}
                    </th>
                  </tr>
                  <tr
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <th
                      colSpan="8"
                      style={{
                        textAlign: "left",
                        padding: "7px",
                        borderTop: "1px black solid",
                        borderBottom: "1px black solid",
                        margin: "0px",
                        alignItems: "center",
                        wordWrap: "break-word",
                        fontWeight: "normal",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Comments :&nbsp; {maintReportData?.maintenance?.approver_comment ? maintReportData?.maintenance?.approver_comment: ""}
                      </span>
                    </th>
                    <th
                      style={{
                        border: "1px solid",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {maintReportData?.maintenance?.ma_signature ? (
                        
                        <S3BucketImage
                          imageUrl={maintReportData?.maintenance?.ma_signature}
                          widthSize={"100px"}
                        />
                      ) : (
                        "-NA-"
                      )}
                      <br />
                      <span>Signature</span>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan='9'>
                      {maintReportData?.maintenance?.geo_loc?.coordinates?.length > 0 ? (
                        <div>{`Approver Geo Location - Lat : ${convertToDMS(maintReportData?.maintenance?.geo_loc?.coordinates[1], true)} & Long : ${convertToDMS(maintReportData?.maintenance?.geo_loc?.coordinates[0], false)}`}</div>
                      ) : (
                        "Approver Geo Location - Lat : NA Long : NA"
                      )}
                    </th>
                  </tr>
                </>
              }
            </thead>
          </table>
        </div>
      })}

    </>
  );
};

export default MaintenanceReport;
