//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import IMAGENAME from "../../../medias/media.index";
import Commonheader from "../../../layouts/common/commonHeader";
import { useSelector, useDispatch } from "react-redux";
import "./reportsStyle.css";
import AWS from "aws-sdk";
import S3BucketImage from "./s3BucketImages";

const convertToDMS = (coordinate, isLatitude) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

  const direction = isLatitude
    ? coordinate >= 0
      ? "N"
      : "S"
    : coordinate >= 0
    ? "E"
    : "W";

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
};

/////////////////////////AWS Operations ends/////////////////////////
const MaintenanceActivity = (props) => {
  const { MaintenanceActivity, activityName } = props;

  //useEffects
  useEffect(() => {
    // console.log("MaintenanceActivity", MaintenanceActivity);
  }, [props]);

  //////////////////////////////////////HTML////////////////////////////////////////
  return (
    <div style={{ margin: "auto" }}>
      <table
        className="table"
        style={{
          width: 1000,
          margin: "auto",
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <thead style={{ verticalAlign: "middle" }}>
          <tr>
            <th
              colSpan="9"
              style={{
                fontWeight: "bold",
                backgroundColor: "#0862B4",
                color: "white",
                textAlign: "center",
                borderBottom: "none",
                padding: "7px",
                borderTop: "1px black",
                margin: "0px",
                alignItems: "center",
              }}
            >
              {activityName}
            </th>
          </tr>
          <tr style={{ verticalAlign: "middle" }}>
            <th colSpan="3" style={{ border: "1px solid" }}>
              {MaintenanceActivity?.start_date !== null ? (
                <div style={{ width: "100%" }}>
                  {MaintenanceActivity?.start_date ? (
                    <p style={{ verticalAlign: "middle", margin: "0px" }}>
                      &nbsp; Start Date & Time -&nbsp;
                      {`${moment(MaintenanceActivity?.start_date).format(
                        "MMMM Do YYYY | hh:mm:ss A"
                      )}`}
                      &nbsp;
                    </p>
                  ) : (
                    <p style={{ verticalAlign: "middle", margin: "0px" }}>
                      &nbsp; Start Date & Time - NA&nbsp;
                    </p>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  {/* <p>Start Date & Time - NA </p> */}
                  <p style={{ verticalAlign: "middle", margin: "0px" }}>
                    &nbsp; Start Date & Time - NA&nbsp;
                  </p>
                </div>
              )}
            </th>
            <th colSpan="3" style={{ border: "1px solid" }}>
              {MaintenanceActivity?.end_date !== null ? (
                <div style={{ width: "100%" }}>
                  {MaintenanceActivity?.end_date ? (
                    <p style={{ verticalAlign: "middle", margin: "0px" }}>
                      End Date & Time -&nbsp;
                      {`${moment(MaintenanceActivity?.end_date).format(
                        "MMMM Do YYYY | hh:mm:ss A"
                      )}`}
                      &nbsp;
                    </p>
                  ) : (
                    <p style={{ verticalAlign: "middle", margin: "0px" }}>
                      End Date & Time - NA&nbsp;
                    </p>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <p style={{ verticalAlign: "middle", margin: "0px" }}>
                    End Date & Time - NA&nbsp;
                  </p>
                </div>
              )}
            </th>
            <th colSpan="3" style={{ border: "1px solid" }}>
              Activity Duration -&nbsp;
              {moment
                .utc(
                  moment
                    .duration(MaintenanceActivity?.ma_duration, "seconds")
                    .asSeconds() * 1000
                )
                .format("HH:mm:ss")}
            </th>
          </tr>
          <tr>
            <th
              scope="col"
              style={{
                wordWrap: "break-word",
                width: "70px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              Task No
            </th>
            <th
              scope="col"
              colSpan="2"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Checkpoint
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Standard Requirement
            </th>
            <th
              scope="col"
              colSpan="2"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Response
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Comments
            </th>
            <th
              scope="col"
              style={{
                width: "110px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              Before Image
            </th>
            <th
              scope="col"
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              After Image
            </th>
          </tr>
        </thead>
        {MaintenanceActivity?.checkpoints?.length !== 0
          ? MaintenanceActivity?.checkpoints?.map((eachData, index) => {
              return (
                <tbody style={{ width: "100%" }} key={index}>
                  <tr
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.checkpoint}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.standard_requirement}
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        wordWrap: "break-word",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* {(eachData.answer === "yes" || eachData.answer === "no") ?
                      eachData.answer.charAt(0).toUpperCase() + eachData.answer.slice(1) :
                      eachData.answer 
                    } */}
                      {eachData.cp_answer === "yes"
                        ? eachData.cp_answer.charAt(0).toUpperCase() +
                          eachData.cp_answer.slice(1)
                        : eachData.cp_answer === "not_applicable"
                        ? "Not Applicable"
                        : eachData.cp_answer === "no"
                        ? eachData.cp_answer.charAt(0).toUpperCase() +
                          eachData.cp_answer.slice(1)
                        : eachData.cp_answer}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      {eachData.cp_comments}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* {eachData.cp_before_pic !== "" ? "Y" : '-'} */}
                      {eachData.cp_before_pic ? (
                        <S3BucketImage
                          imageUrl={eachData.cp_before_pic}
                          widthSize={"6rem"}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        border: "1px solid black",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* {eachData.cp_after_pic !== "" ? "Y" : '-'} */}
                      {eachData.cp_after_pic ? (
                        <S3BucketImage
                          imageUrl={eachData.cp_after_pic}
                          widthSize={"6rem"}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : null}
      </table>
      <table
        className="table"
        style={{
          margin: "auto",
          width: 1000,
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          <tr
            style={{
              verticalAlign: "middle",
            }}
          >
            <th
              colSpan="8"
              style={{
                textAlign: "left",
                padding: "7px",
                borderTop: "1px black solid",
                borderBottom: "1px black solid",
                margin: "0px",
                alignItems: "center",
                wordWrap: "break-word",
                fontWeight: "normal",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Additional Comments :&nbsp;
              </span>
              {MaintenanceActivity?.ma_comment}
            </th>
            <th
              style={{
                border: "1px solid",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {MaintenanceActivity?.ma_signature ? (
                // <img
                //   src={MaintenanceActivity?.ma_signature}
                //   alt="logo"
                //   style={{
                //     width: "100px",
                //     border: '1px solid',
                //     verticalAlign: 'middle'
                //   }}
                // ></img>
                <S3BucketImage
                  imageUrl={MaintenanceActivity?.ma_signature}
                  widthSize={"100px"}
                />
              ) : (
                "-NA-"
              )}
              {/* <S3BucketImage imageUrl={MaintenanceActivity.ma_signature} /> */}
              <br />
              <span>Signature</span>
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="table"
        style={{
          margin: "auto",
          width: 1000,
          border: "1px solid black",
          borderBottom: "none",
          tableLayout: "fixed",
          marginLeft: "auto",
        }}
      >
        <thead style={{ width: "100%" }}>
          {/* {MaintenanceActivity?.performer_info?.length !== 0
            ? MaintenanceActivity?.performer_info.map((eachData, index) => {
                return (
                  <>
                  <tr>
                    <th colSpan="3">
                      {eachData?.performed_by?.first_name &&
                      eachData?.performed_by?.first_name !==
                        "undefined undefined undefined" ? (
                        <div>{`Performed by - ${eachData?.performed_by?.first_name}`}</div>
                      ) : (
                        "Performed by - -"
                      )}
                    </th>
                    <th colSpan="3">
                      {eachData?.performed_by?.designation ? (
                        <div>{`Designation - ${(eachData?.performed_by?.designation).replace(
                          "_",
                          " "
                        )}`}</div>
                      ) : (
                        "Designation - -"
                      )}
                    </th>
                    <th colSpan="3">
                      {eachData?.performed_by?.email ? (
                        <div>{`Email - ${eachData?.performed_by?.email}`}</div>
                      ) : (
                        "Email - -"
                      )}
                    </th>
                    
                  </tr>
                  <tr>
                    <th colSpan="9">
                      {eachData?.geo_loc?.coordinates?.length>0 ? (
                        <div>{`Performer Geo Location - Lat : ${convertToDMS(eachData?.geo_loc?.coordinates[1],true)} & Long : ${convertToDMS(eachData?.geo_loc?.coordinates[0],false)}`}</div>
                      ) : (
                        "Performer Geo Location - Lat : NA & Long : NA"
                      )}
                    </th>
                    </tr>
                  </>
                );
              })
            : null} */}
          {MaintenanceActivity?.performer_info?.length !== 0 ? (
            <>
              {(() => {
                const lastPerformerInfo =
                  MaintenanceActivity?.performer_info?.[
                    MaintenanceActivity?.performer_info.length - 1
                  ];
                return lastPerformerInfo ? (
                  <>
                    <tr>
                      <th colSpan="3">
                        {lastPerformerInfo?.performed_by?.first_name &&
                        lastPerformerInfo?.performed_by?.first_name !==
                          "undefined undefined undefined" ? (
                          <div>{`Performed by - ${lastPerformerInfo?.performed_by?.first_name}`}</div>
                        ) : (
                          "Performed by - -"
                        )}
                      </th>
                      <th colSpan="3">
                        {lastPerformerInfo?.performed_by?.designation ? (
                          <div>{`Designation - ${lastPerformerInfo?.performed_by?.designation.replace(
                            "_",
                            " "
                          )}`}</div>
                        ) : (
                          "Designation - -"
                        )}
                      </th>
                      <th colSpan="3">
                        {lastPerformerInfo?.performed_by?.email ? (
                          <div>{`Email - ${lastPerformerInfo?.performed_by?.email}`}</div>
                        ) : (
                          "Email - -"
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="9">
                        {lastPerformerInfo?.geo_loc?.coordinates?.length > 0 ? (
                          <div>{`Performer Geo Location - Lat : ${convertToDMS(
                            lastPerformerInfo?.geo_loc?.coordinates[1],
                            true
                          )} & Long : ${convertToDMS(
                            lastPerformerInfo?.geo_loc?.coordinates[0],
                            false
                          )}`}</div>
                        ) : (
                          "Performer Geo Location - Lat : NA & Long : NA"
                        )}
                      </th>
                    </tr>
                  </>
                ) : null;
              })()}
            </>
          ) : null}
        </thead>
      </table>
    </div>
  );
};

export default MaintenanceActivity;
